import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  Grid,
  Link,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MouseEvent, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import theme from "../../common/theme";
import { listAudiences } from "../../http/audience";
import { Audience } from "../../types/audience";
import PageLayout from "../Layout/PageLayout";

import { DashboardItem } from "./DashboardItem";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(4, 0),
  },
  grid: {
    width: "100%",
  },
});

export const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [currentAudience, setCurrentAudience] = useState<Audience | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChangeCurrentAudience = (event: SelectChangeEvent) => {
    setCurrentAudience(
      audiences.find(
        (audience) => audience.name === event.target.value,
      ) as Audience,
    );
  };

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        const response = await listAudiences(1, 50);
        const list = response.data.items;
        if (active) {
          setAudiences(list);
          if (list.length > 0) {
            setCurrentAudience(list[0]);
          } else setCurrentAudience(null);
        }
      } catch (error: any) {
        setCurrentAudience(null);
        console.error(error.response?.data.message || error.message);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  return (
    <PageLayout>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sx={{ display: { xxs: "none", xs: "block" } }}>
            <Typography variant="h4">Dashboard</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          {audiences.length === 0 ? (
            <>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: { xxs: "none", xs: "block" } }}
              >
                <Typography variant="h5" sx={{ pb: 3 }}>
                  Current audience
                </Typography>
                <Typography variant="body1" sx={{ pb: 3 }}>
                  You don&apos;t have any audience.{" "}
                  <Link
                    component={RouterLink}
                    to="/audiences/create"
                    underline="none"
                  >
                    Create new audience?
                  </Link>
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: { xxs: "none", xs: "block" } }}
              >
                <Typography variant="h5" sx={{ pb: 3 }}>
                  Current audience
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={currentAudience?.name || ""}
                    defaultValue={currentAudience?.name || ""}
                    onChange={onChangeCurrentAudience}
                  >
                    {audiences.map((audience, index) => (
                      <MenuItem key={index} value={audience.name}>
                        <Typography variant="body1">{audience.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
                    This audience has {currentAudience?.numberOfContacts}{" "}
                    contact
                    {currentAudience && currentAudience.numberOfContacts < 2
                      ? ""
                      : "s"}
                    .
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item md={3} sx={{ display: { xs: "none", md: "block" } }} />
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: { xxs: "none", xs: "block" } }}
              >
                <ButtonGroup variant="contained">
                  <Button
                    sx={{
                      textTransform: "none",
                      minWidth: "max-content",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() =>
                      navigate(`/audiences/view/${currentAudience?.id}`)
                    }
                  >
                    View Audience
                  </Button>
                  <Button
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickMenu}
                  >
                    <KeyboardArrowDownIcon />
                  </Button>
                </ButtonGroup>
                <Menu
                  id="basic-menu"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{ sx: { mt: 1 } }}
                >
                  <Link
                    component={RouterLink}
                    to="/audiences"
                    underline="none"
                    style={{ color: "inherit" }}
                  >
                    <MenuItem value="Manage Audience">
                      <Typography>Manage Audience</Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    component={RouterLink}
                    to={`/audiences/view/${currentAudience?.id}`}
                    underline="none"
                    style={{ color: "inherit" }}
                  >
                    <MenuItem value="View Audience">
                      <Typography>View Audience</Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    component={RouterLink}
                    to={`/audiences/${currentAudience?.id}/import`}
                    underline="none"
                    style={{ color: "inherit" }}
                  >
                    <MenuItem value="View Audience">
                      <Typography>Import Contacts</Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={12} sx={{ display: { xxs: "none", xs: "block" } }}>
            <Typography variant="h5">Insights</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xxs: "none", xs: "block" } }}
          >
            <DashboardItem
              icon={<BarChartOutlinedIcon />}
              title="Recent growth"
              description="New contacts added to this audience in the last 30 days"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={12} sx={{ display: { xxs: "none", xs: "block" } }}>
            <Typography variant="h5">Engagement</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xxs: "none", xs: "block" } }}
          >
            <DashboardItem
              icon={<DraftsOutlinedIcon />}
              title="Email marketing engagement"
              description="Your subscribers, broken down by how often they open and click your emails"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xxs: "none", xs: "block" } }}
          >
            <DashboardItem
              icon={<LocationOnOutlinedIcon />}
              title="Top locations"
              description="Based on your contact’s IP address when they interact with your emails"
            />
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  );
};
