import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import path from "path-browserify";
import { useState } from "react";

import { ImportedFromSource } from "../../constants/audience";
import { useToast } from "../../contexts/ToastContext";
import {
  importContactsFromBigQuery,
  importContactsFromCsv,
} from "../../http/audience";
import { Audience } from "../../types/audience";
import { BqQuery } from "../../types/bq-query";

import { BigQueryFetch } from "./BigQueryFetch";
import { CopyPasteContacts } from "./CopyPasteContacts";
import { CsvUpload } from "./CsvUpload";

export const ImportContacts = ({
  audience,
  method,
  handleNextStep,
  handleBackStep,
  handleResetStep,
}: {
  audience: Audience | null;
  method: string;
  handleNextStep: () => void;
  handleBackStep: () => void;
  handleResetStep: (step: number) => void;
}) => {
  const { openToast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const [contactsInText, setContactsInText] = useState<string>("");
  const [queryInput, setQueryInput] = useState<string>(audience?.query || ""); // query to fetch
  const [querySample, setQuerySample] = useState<BqQuery | null>(null); // query from autocomplete
  const [inputError, setInputError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const writeToCsv = (content: string) => {
    const blob = new Blob([content], {
      type: "text/csv;charset=utf-8",
    });
    return new File([blob], "contactsInText.csv", { lastModified: Date.now() });
  };

  const onChangeQuerySample = (newQuery: BqQuery | null) => {
    setQuerySample(newQuery);
    setQueryInput(newQuery?.query || "");
    setInputError("");
  };

  const checkInput = (method: string): string => {
    switch (method) {
      case ImportedFromSource.CSV: {
        if (!file) return "No CSV file found";
        else if (path.extname(file.name) !== ".csv")
          return "Only CSV file allowed";
        else return "";
      }
      case ImportedFromSource.TEXT: {
        if (!contactsInText.length) return "Empty content";
        else return "";
      }
      case ImportedFromSource.BIG_QUERY: {
        if (!queryInput.length) {
          return "No query found";
        } else return "";
      }
      default:
        return "Input error";
    }
  };

  const onNextStep = async () => {
    const checkError = checkInput(method);
    let isFalse = false;
    setInputError(checkError);
    if (!checkError.length) {
      setIsLoading(true);
      try {
        switch (method) {
          case ImportedFromSource.CSV: {
            if (file && audience) {
              await importContactsFromCsv(audience.id, file, method);
            }
            break;
          }
          case ImportedFromSource.TEXT: {
            if (contactsInText.length && audience) {
              await importContactsFromCsv(
                audience.id,
                writeToCsv(contactsInText),
                method,
              );
            }
            break;
          }
          case ImportedFromSource.BIG_QUERY: {
            if (queryInput.length && audience) {
              await importContactsFromBigQuery(audience.id, {
                query: queryInput,
              });
            }
            break;
          }
          default:
            break;
        }
      } catch (error: any) {
        isFalse = true;
        openToast(
          "error",
          error.response?.data.message || "Something went wrong",
        );
      } finally {
        setIsLoading(false);
      }

      if (!isFalse) {
        handleNextStep();
        return;
      }
      handleResetStep(0);
    }
  };

  const renderImportMethod = () => {
    if (!audience) return;
    switch (method) {
      case ImportedFromSource.CSV:
        return <CsvUpload file={file} onChangeFile={setFile} />;
      case ImportedFromSource.TEXT:
        return (
          <CopyPasteContacts
            contactsInText={contactsInText}
            setContactsInText={setContactsInText}
          />
        );
      case ImportedFromSource.BIG_QUERY:
        return (
          <BigQueryFetch
            querySample={querySample}
            onChangeQuerySample={onChangeQuerySample}
            queryInput={queryInput}
            onChangeQueryInput={setQueryInput}
            hasInputError={!!inputError.length}
          />
        );
      default:
        return <CsvUpload file={file} onChangeFile={setFile} />;
    }
  };

  return (
    <>
      <Box sx={{ minHeight: "300px" }}>
        {renderImportMethod()}
        <Typography color="error" sx={{ py: 2 }}>
          {inputError}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" onClick={handleBackStep} sx={{ mr: 1 }}>
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton
          loading={isLoading}
          onClick={onNextStep}
          color={inputError.length ? "error" : "primary"}
        >
          Next
        </LoadingButton>
      </Box>
    </>
  );
};
