import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import { ColDef } from "ag-grid-community";
import { useEffect } from "react";
import { FC, forwardRef, ReactElement, Ref, useState } from "react";

import theme from "../../common/theme";
import { getAudienceHeaders } from "../../http/audience";
import {
  testRenderEmailWithContact,
  validateCampaign,
} from "../../http/campaign";
import { Campaign } from "../../types/campaign";
import { ICampaignValidation } from "../../types/campaign";
import GridComponent from "../GridComponent";

interface IProps {
  open: boolean;
  campaign: Campaign;
  handleClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CampaignPreviewDialog: FC<IProps> = ({ open, campaign, handleClose }) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[] | null>(null);
  const [previewHTML, setPreviewHTML] = useState<string>(
    campaign.template?.html || "",
  );
  const [previewSubject, setPreviewSubject] = useState<string>(
    campaign.subject || "",
  );
  const [previewText, setPreviewText] = useState<string>(
    campaign.previewText || "",
  );
  const [validation, setValidation] = useState<ICampaignValidation[]>([]);

  useEffect(() => {
    if (open && !columnDefs && campaign.audienceId && campaign.template) {
      _getAudienceHeaders();
      _validateCampaign();
    }
  }, [open, columnDefs, campaign.audienceId, campaign.template]);

  useEffect(() => {
    setPreviewHTML(campaign.template?.html || "");
  }, [campaign.template]);

  useEffect(() => {
    setPreviewSubject(campaign.subject || "");
  }, [campaign.subject]);

  useEffect(() => {
    setPreviewText(campaign.previewText || "");
  }, [campaign.previewText]);

  const _getAudienceHeaders = async () => {
    if (!campaign) {
      return;
    }

    try {
      const response = await getAudienceHeaders(campaign.audienceId || 0);
      const cols: ColDef[] = response.data.map((item) => {
        return {
          id: item.id,
          field: item.header,
          pinned: item.header === "email" ? "left" : undefined,
        };
      });
      cols.push({
        field: "id",
        hide: true,
      });
      setColumnDefs(cols);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _validateCampaign = async () => {
    if (!campaign) {
      return;
    }
    try {
      const response = await validateCampaign({
        templateId: campaign.template?.id || 0,
        audienceId: campaign.audienceId || 0,
        subject: campaign.subject || "",
        previewText: campaign.previewText || "",
      });
      setValidation(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const onContactSelected = async (contactId: string) => {
    try {
      const response = await testRenderEmailWithContact({
        templateId: campaign.template?.id || 0,
        contactId,
        subject: campaign.subject || "",
        previewText: campaign.previewText || "",
      });
      setPreviewHTML(response.data.template);
      setPreviewSubject(response.data.subject);
      setPreviewText(response.data.previewText);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _handleClose = () => {
    setColumnDefs(null);
    handleClose();
  };

  const validationColDefs: ColDef[] = [
    { field: "email", pinned: "left" },
    { field: "contactId", hide: true },
    { field: "missingFields" },
  ];

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={_handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            size={"small"}
            onClick={_handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }}>
            Preview: <b>{campaign.name}</b>
          </Typography>
          <Button autoFocus color="inherit" onClick={_handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <iframe srcDoc={previewHTML} width="100%" height="50%"></iframe>
          <Box
            className="ag-theme-alpine"
            sx={{ width: "100%", height: "50%" }}
          >
            <GridComponent
              columnDefs={columnDefs || []}
              rowModelType={"serverSide"}
              httpEndpoint={`/api/audiences/${campaign.audienceId}/contacts?`}
              pagination={true}
              dataKey={"data"}
              idKey={"id"}
              onRowClicked={(e) => onContactSelected(e.data.id)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <Stack
            direction="column"
            sx={{ height: "51%", pt: theme.spacing(3) }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography>
                Subject: <b>{previewSubject}</b>
              </Typography>
              <Typography>
                Preview Text: <b>{previewText}</b>
              </Typography>
              <Typography>
                Audience: <b>{campaign.audienceName || ""}</b>
              </Typography>
            </Box>
            {validation.length ? (
              <Alert severity="error">
                <b>
                  Campaign Validation Failed!{" "}
                  <i>{validation.length} contacts</i> have missing fields
                </b>
              </Alert>
            ) : (
              <Alert severity="success">
                <b>
                  Campaign Validation Successfull! No contact has any missing
                  field
                </b>
              </Alert>
            )}
          </Stack>
          <Box
            className="ag-theme-alpine"
            sx={{ width: "100%", height: "49%" }}
          >
            <GridComponent
              columnDefs={validationColDefs || []}
              pagination={true}
              rowData={validation}
              onRowClicked={(e) => onContactSelected(e.data.contactId)}
            />
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CampaignPreviewDialog;
