import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { FC, useEffect, useMemo, useState } from "react";

import theme from "../../common/theme";
import { getClickReport } from "../../http/report";
import { Campaign } from "../../types/campaign";
import { CampaignHyperLink } from "../../types/campaign-hyperlink";
import { IClickReport } from "../../types/report";

import ClickMap from "./ClickMap";

interface IProps {
  campaign: Campaign;
}

const useStyles = makeStyles(() => ({
  trackUrl: {
    maxWidth: 0,
    overflowWrap: "break-word",
    "& > a": {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));

const ClickPerformance: FC<IProps> = ({ campaign }: IProps) => {
  const classes = useStyles();
  const [links, setLinks] = useState<IClickReport[]>([]);
  const [isClickMapVisible, setIsClickMapVisible] = useState<boolean>(false);

  useEffect(() => {
    _getClickPerformance();
  }, []);

  const _getClickPerformance = async () => {
    try {
      const response = await getClickReport(campaign.id);
      setLinks(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const totalClicks = useMemo(
    () =>
      links
        ? links.reduce(
            (n: number, { clicks }: { clicks: number }) => n + clicks,
            0,
          )
        : 0,
    [links],
  );

  const totalUniqueClicks = useMemo(
    () =>
      links
        ? links.reduce(
            (n: number, { uniqueClicks }: { uniqueClicks: number }) =>
              n + uniqueClicks,
            0,
          )
        : 0,
    [links],
  );

  const handleCloseClickMap = () => {
    setIsClickMapVisible(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            style={{ flex: 1, marginTop: theme.spacing(2) }}
          >
            Links in this email
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => setIsClickMapVisible(true)}
          >
            View Click Map
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ mt: theme.spacing(3) }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="60%">URL</TableCell>
              <TableCell align="right">Total clicks ({totalClicks})</TableCell>
              <TableCell align="right">
                Unique clicks ({totalUniqueClicks})
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {links.map((link: CampaignHyperLink) => (
              <TableRow
                key={link.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.trackUrl}
                >
                  {link.href}
                </TableCell>
                <TableCell align="right">
                  {link.clicks} (
                  {totalClicks
                    ? ((link.clicks / totalClicks) * 100).toFixed(1)
                    : 0}
                  %)
                </TableCell>
                <TableCell align="right">
                  {link.uniqueClicks} (
                  {totalUniqueClicks
                    ? ((link.uniqueClicks / totalUniqueClicks) * 100).toFixed(1)
                    : 0}
                  %)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ClickMap
        open={isClickMapVisible}
        campaign={campaign}
        links={links}
        totalClicks={totalClicks}
        totalUniqueClicks={totalUniqueClicks}
        handleClose={handleCloseClickMap}
      />
    </>
  );
};

export default ClickPerformance;
