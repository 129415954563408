import { MergeTag } from "react-email-editor";

export const OPEN_DRAWER_WIDTH = 250;
export const CLOSE_DRAWER_WIDTH = 80;

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export const MERGE_TAGS: MergeTag[] = [
  {
    name: "Email",
    value: "{{ email }}",
  },
  {
    name: "Address",
    value: "{{ address }}",
  },
  {
    name: "Username",
    value: "{{ username }}",
  },
  {
    name: "UserName",
    value: "{{ userName }}",
  },
  {
    name: "FullName",
    value: "{{ fullName }}",
  },
  {
    name: "Fullname",
    value: "{{ fullname }}",
  },
  {
    name: "Location",
    value: "{{ location }}",
  },
  {
    name: "Birthday",
    value: "{{ birthday }}",
  },
  {
    name: "Grade",
    value: "{{ grade }}",
  },
  {
    name: "Phone",
    value: "{{ phone }}",
  },
  {
    name: "Country",
    value: "{{ country }}",
  },
];

export const CURRENT_TIMEZONE_OFFSET =
  new Date().getTimezoneOffset() < 0
    ? -new Date().getTimezoneOffset() / 60
    : new Date().getTimezoneOffset() / 60;
