import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";

import { listBqQueries } from "../../http/bq-query";
import { BqQuery } from "../../types/bq-query";

export const BigQueryFetch = ({
  querySample,
  onChangeQuerySample,
  queryInput,
  onChangeQueryInput,
  hasInputError,
}: {
  querySample: BqQuery | null;
  onChangeQuerySample: (newQuery: BqQuery | null) => void;
  queryInput: string;
  onChangeQueryInput: (newQuery: string) => void;
  hasInputError: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [queries, setQueries] = useState<BqQuery[]>([]);

  const onChangeCurrentQuery = (
    _event: SyntheticEvent,
    newQuery: BqQuery | null,
  ) => {
    onChangeQuerySample(newQuery);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        const response = await listBqQueries();
        const list = response.data;
        if (list.length > 0 && active) {
          setQueries(list);
          if (!queryInput.length) onChangeQuerySample(list[0]);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  return (
    <Box sx={{ minHeight: "300px" }}>
      <Typography variant="h5" sx={{ pb: 3, pt: 1 }}>
        Select the query which you would like to fetch:
      </Typography>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Autocomplete
          fullWidth
          value={querySample}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={onChangeCurrentQuery}
          inputValue={inputValue || ""}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => option.name}
          options={queries}
          renderInput={(params) => (
            <TextField {...params} label="Query sample" />
          )}
        />
        <TextField
          variant="outlined"
          size="medium"
          label="Query to fetch"
          error={hasInputError}
          value={queryInput || ""}
          onChange={(event) => onChangeQueryInput(event.target.value)}
          required
          fullWidth
          multiline
        />
      </Stack>
    </Box>
  );
};
