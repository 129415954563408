import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { useState } from "react";

export const RenameDialog = ({
  open,
  object,
  oldName,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  object: string;
  oldName: string;
  handleClose: () => void;
  handleSubmit: (newName: string) => void;
}) => {
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const onClose = () => {
    setName("");
    setError(false);
    handleClose();
  };

  const onSubmit = (name: string) => {
    if (!name.trim().length) {
      setError(true);
      return;
    }

    handleSubmit(name);
    onClose();
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Rename {object}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ py: 2 }}>
          What should we rename this {object}?
        </DialogContentText>
        <TextField
          margin="dense"
          id="old-name"
          label="Current Name"
          fullWidth
          disabled
          variant="outlined"
          value={oldName}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label="New Name"
          fullWidth
          variant="outlined"
          value={name || ""}
          onChange={(event) => setName(event.target.value)}
          error={error}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", py: 2, px: 3 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(name)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
