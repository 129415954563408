import { TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";

export const CopyPasteContacts = ({
  contactsInText,
  setContactsInText,
}: {
  contactsInText: string;
  setContactsInText: (text: string) => void;
}) => {
  const handleChangeText = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setContactsInText(event?.target.value);
  };

  return (
    <>
      <Typography variant="h5" sx={{ pb: 3, pt: 1 }}>
        Copy and paste your contacts
      </Typography>
      <TextField
        multiline
        fullWidth
        required
        minRows={4}
        placeholder={
          "Email\t\t\t\t\t\tUsername\tFull Name\nbdorsey0@pcworld.com\t\tbdorsey0\tBrietta Dorsey\nkstannion1@columbia.edu\tkstannion1\tKylie Stannion\nctrickett2@squidoo.com\t\tctrickett2\tCoralie Trickett"
        }
        helperText={"Paste your contact information into this field"}
        value={contactsInText}
        onChange={handleChangeText}
      />
    </>
  );
};
