import { Avatar, AvatarProps } from "@mui/material";
import { SxProps } from "@mui/system";

export const TextAvatar = ({
  displayedName,
  sx,
  props,
}: {
  displayedName: string;
  sx?: SxProps;
  props?: AvatarProps;
}) => {
  function stringToColor(str: string) {
    let hash = 0;
    let i: number;

    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
  }

  function styleAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        height: "50px",
        width: "50px",
        ...sx,
      },
      children: `${name[0]}`,
      ...props,
    };
  }

  return <Avatar {...styleAvatar(displayedName)} />;
};
