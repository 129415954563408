import { ColumnDataType } from "../constants/audience";

import { Profile } from "./profile";
import { Template } from "./template";

export interface Campaign {
  id: number;
  name: string;
  fromEmail?: string;
  fromName?: string;
  subject?: string;
  status?: CAMPAIGN_STATUS;
  previewText?: string;
  audienceId?: number;
  audienceName?: string;
  numberOfContacts?: number;
  templateId?: number;
  template?: Template;
  html: string;
  deliveryDate: Date;
  jobsSent?: number;
  uniqueOpens?: number;
  uniqueClicks?: number;
  schedule?: ICampaignSchedule;
  updatedByProfile?: Profile;
  audienceHeaders?: CampaignAudienceHeader[];
  updatedAt: string;
  errorMessage?: string;
}

export interface ICampaignSchedule {
  gmt: number;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
}

export enum CAMPAIGN_STATUS {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  PAUSED = "PAUSED",
  SENT = "SENT",
  ERROR = "ERROR",
  RUNNING = "RUNNING",
}

export interface ICampaignValidation {
  contactId: string;
  email: string;
  missingFields: string[];
}

export interface ITimezone {
  value: number;
  text: string;
}

export interface CampaignAudienceHeader {
  header: string;
  dataType: ColumnDataType;
}
