export interface ColumnHeader {
  header: string;
  field: string;
}

export const audienceHeaders: ColumnHeader[] = [
  {
    header: "Name",
    field: "name",
  },
  {
    header: "Contacts",
    field: "numberOfContacts",
  },
  {
    header: "Imported From",
    field: "source",
  },
  {
    header: "Created At",
    field: "createdAt",
  },
];

export enum ImportedFromSource {
  CSV = "CSV",
  TEXT = "TEXT",
  BIG_QUERY = "BIG_QUERY",
  FILTERS = "FILTERS",
}

export enum ColumnDataType {
  TEXT = "string",
  NUMBER = "number",
  DATE = "datetime",
  PHONE_NUMBER = "phoneNumber",
}
