import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
    black: Palette["primary"];
  }
  interface PaletteOptions {
    white?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
  }
}

const palette = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#000000",
  },
};

const breakpoints = {
  values: {
    xxs: 240,
    xs: 480,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const theme = createTheme({ breakpoints: breakpoints, palette: palette });
export default theme;
