import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import GridComponent from "../components/GridComponent";
import PageLayout from "../components/Layout/PageLayout";
import useQuery from "../hooks/useQuery";

export default function Search() {
  const emailRef = useRef<string>("");
  const navigate = useNavigate();
  const query = useQuery();
  const [email, setEmail] = useState<string>(query.get("email") || "");

  useEffect(() => {
    if (email.trim().length) {
      navigate({
        pathname: "/search",
        search: "?email=" + email,
      });
    }
  }, [email]);

  const search = () => {
    setEmail(emailRef.current);
  };

  const campaignColumnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "updatedAt",
      headerName: "Last updated",
      cellRenderer: (params: ICellRendererParams) => {
        return moment(params.value).format("YYYY-MM-DD HH:mm:ss A");
      },
    },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "data",
      headerName: "Contact Data",
      cellRenderer: (params: ICellRendererParams) => {
        return Object.keys(params.value)
          .map((key) => `<b>${key}</b>: ${params.value[key]}`)
          .join(" | ");
      },
    },
  ];

  const audienceColumnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "updatedAt",
      headerName: "Last updated",
      cellRenderer: (params: ICellRendererParams) => {
        return moment(params.value).format("YYYY-MM-DD HH:mm:ss A");
      },
    },
    {
      field: "source",
      headerName: "Source",
    },
    {
      field: "data",
      headerName: "Contact Data",
      cellRenderer: (params: ICellRendererParams) => {
        return Object.keys(params.value)
          .map((key) => `<b>${key}</b>: ${params.value[key]}`)
          .join(" | ");
      },
    },
  ];
  return (
    <PageLayout>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ pb: 3 }}>
          Search
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 3 }} />
        <Stack direction="row">
          <TextField
            inputRef={(e) => {
              if (e) {
                e.value = email;
                e.focus();
              }
            }}
            label="Email"
            onChange={(e) => {
              emailRef.current = e.target.value;
            }}
            fullWidth
            variant="outlined"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
          <Button
            variant="contained"
            sx={{ ml: 4, minWidth: 200 }}
            onClick={() => search()}
          >
            Search
          </Button>
        </Stack>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5">Campaigns</Typography>
          <Box
            className="ag-theme-alpine"
            sx={{ width: "100%", height: 500, mt: 3 }}
          >
            <GridComponent
              columnDefs={campaignColumnDefs}
              rowModelType={"serverSide"}
              httpEndpoint={
                email.trim().length
                  ? `/api/search/campaign-by-email?email=${encodeURIComponent(
                      email,
                    )}&`
                  : undefined
              }
              pagination={true}
              onRowClicked={(e) => {
                navigate(`/campaigns/show/${e.data.id}`);
              }}
              sideBar={{ toolPanels: ["columns"] }}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h5">Audiences</Typography>
          <Box
            className="ag-theme-alpine"
            sx={{ width: "100%", height: 500, mt: 3 }}
          >
            <GridComponent
              columnDefs={audienceColumnDefs}
              rowModelType={"serverSide"}
              httpEndpoint={
                email.trim().length
                  ? `/api/search/audience-by-email?email=${encodeURIComponent(
                      email,
                    )}&`
                  : undefined
              }
              pagination={true}
              onRowClicked={(e) => {
                navigate(`/audiences/view/${e.data.id}`);
              }}
              sideBar={{ toolPanels: ["columns"] }}
            />
          </Box>
        </Box>
      </Container>
    </PageLayout>
  );
}
