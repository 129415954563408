import axios from "axios";

import { Campaign, ICampaignValidation, ITimezone } from "../types/campaign";
import {
  CampaignValidationDTO,
  Pagination,
  SaveCampaignDTO,
  TestEmailDTO,
  TestRenderEmailWithContactDTO,
} from "../types/http";

export const saveCampaign = (body: SaveCampaignDTO) => {
  return axios.post<Campaign>("/api/campaigns", body);
};

export const updateCampaign = (id: number, body: SaveCampaignDTO) => {
  return axios.put(`/api/campaigns/${id}`, body);
};

export const listCampaigns = (name = "", page = 1, limit = 10) => {
  let url = `/api/campaigns?page=${page}&limit=${limit}`;
  if (name.trim().length) {
    url += `&name=${name}`;
  }
  return axios.get<Pagination<Campaign>>(url);
};

export const getCampaignById = (id: number, jobSuccessCount = false) => {
  return axios.get<Campaign>(
    `/api/campaigns/${id}${jobSuccessCount ? "?job_success_count=true" : ""}`,
  );
};

export const sendCampaignNow = (id: number) => {
  return axios.post<Campaign>(`/api/campaigns/${id}/send`);
};

export const scheduleCampaign = (id: number, body: SaveCampaignDTO) => {
  return axios.post<Campaign>(`/api/campaigns/${id}/schedule`, body);
};

export const pauseCampaign = (id: number) => {
  return axios.post<boolean>(`/api/campaigns/${id}/pause`);
};

export const replicateCampaign = (id: number) => {
  return axios.post<Campaign>(`/api/campaigns/${id}/replicate`);
};

export const getAuthorizedSentEmails = () => {
  return axios.get<string[]>("/api/campaigns/authorized-sent-emails");
};

export const getTimezones = () => {
  return axios.get<ITimezone[]>("/api/campaigns/timezones");
};

export const sendTestEmail = (payload: TestEmailDTO) => {
  return axios.post("/api/campaigns/send-test-email", payload);
};

export const testRenderEmailWithContact = (
  payload: TestRenderEmailWithContactDTO,
) => {
  return axios.post<{ template: string; subject: string; previewText: string }>(
    "/api/campaigns/test-render-email",
    payload,
  );
};

export const validateCampaign = (payload: CampaignValidationDTO) => {
  return axios.post<ICampaignValidation[]>(
    "/api/campaigns/validate-campaign",
    payload,
  );
};

export const deleteCampaignById = (id: number) => {
  return axios.delete<boolean>(`/api/campaigns/${id}`);
};
