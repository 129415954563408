import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { SyntheticEvent, useEffect, useState } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { Link as RouterLink, useParams } from "react-router-dom";

import theme from "../common/theme";
import { CustomTabPanel } from "../components/CustomTabPanel/CustomTabPanel";
import PageLayout from "../components/Layout/PageLayout";
import { getCampaignById } from "../http/campaign";
import { Campaign } from "../types/campaign";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";

// const useStyles = makeStyles(() => ({
//   templateName: {
//     // padding: theme.spacing(4, 0),
//     fontSize: 20,
//   },
//   lastEdit: {
//     fontSize: 16,
//   },
//   type: {
//     fontSize: 16,
//   },
//   templateImg: {
//     marginRight: theme.spacing(2),
//     height: 100,
//     border: "1px dashed grey",
//   },
// }));

export default function CampaignDetail() {
  const [value, setValue] = useState<number>(0);
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      const response = await getCampaignById(parseInt(id), true);

      setCampaign(response.data);
    })();
  }, []);

  useEffect(() => {
    const iframe = document.getElementById(
      "preview-content-html",
    ) as HTMLIFrameElement;
    iframe && iframe.addEventListener("load", handleIframeHeight);

    return () => {
      iframe && iframe.removeEventListener("load", handleIframeHeight);
    };
  }, [campaign, value]);

  function handleIframeHeight(this: HTMLIFrameElement) {
    // do not use arrow function here as we want to keep "this" to point to Iframe Element
    if (this.contentWindow) {
      const iframeBody = this.contentWindow.document.body;
      const height = Math.max(iframeBody.scrollHeight, iframeBody.offsetHeight);
      this.style.height = `${height}px`;
    }
  }

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (campaign === undefined) {
    return <PageLayout></PageLayout>;
  }

  return (
    <PageLayout>
      <Box sx={{ display: "flex", marginBottom: theme.spacing(3) }}>
        <Typography variant="h4" style={{ flex: 1 }}>
          {campaign.name}
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/campaigns/reports/${campaign.id}`}
        >
          View Report
        </Button>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: theme.spacing(3),
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Campaign Preview" />
          <Tab label="HTML Source" />
          <Tab label="Plain-Text Email" />
          <Tab label="Details" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ width: "100%" }}>
          <iframe
            id="preview-content-html"
            srcDoc={campaign.html}
            style={{ border: "none", width: "100%" }}
          ></iframe>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <CodeMirror
          value={campaign.html}
          options={{
            mode: "xml",
            theme: "material",
            lineNumbers: true,
          }}
          editorDidMount={(editor) => {
            const height =
              window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight;
            editor.setSize("100%", height);
            editor.setOption("readOnly", true);
          }}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <pre style={{ overflow: "auto" }}>
          {campaign.template?.plainText || ""}
        </pre>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="40%">
                  <b>Campaign URL</b>
                </TableCell>
                <TableCell>N/A</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Delivery date & time</b>
                </TableCell>
                <TableCell>
                  {moment(campaign.deliveryDate).format(
                    "YYYY-MM-DD HH:mm:ss A",
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>From name</b>
                </TableCell>
                <TableCell>{campaign.fromName}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>From email</b>
                </TableCell>
                <TableCell>{campaign.fromEmail}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Subject line</b>
                </TableCell>
                <TableCell>{campaign.subject}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Preview text</b>
                </TableCell>
                <TableCell>{campaign.previewText}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Recipients</b>
                </TableCell>
                <TableCell>
                  Sent to audience: <b>{campaign.audienceName}</b>
                  <br />
                  Total email sent: <b>{campaign.jobsSent}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CustomTabPanel>
    </PageLayout>
  );
}
