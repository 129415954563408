import axios from "axios";

import { ImportedFromSource } from "../constants/audience";
import {
  Audience,
  AudienceHeader,
  CreateAudienceDto,
  DeleteAudienceDto,
  DeleteAudienceResponse,
} from "../types/audience";
import { ImportBqQueryDto } from "../types/bq-query";
import { Campaign } from "../types/campaign";
import { Contact } from "../types/contact";
import { Pagination } from "../types/http";

export const getAudienceById = (audienceId: number) => {
  return axios.get<Audience>(`/api/audiences/${audienceId}`);
};

export const listAudiences = (
  page = 1,
  limit = 10,
  seachParams: Record<string, string> = {},
) => {
  const searchStr = Object.keys(seachParams)
    .map((key) => `${key}=${seachParams[key]}`)
    .join("&");
  return axios.get<Pagination<Audience>>(
    `/api/audiences?page=${page}&limit=${limit}${
      searchStr.length ? "&" + searchStr : ""
    }`,
  );
};

export const getAudienceHeaders = (id: number) => {
  return axios.get<AudienceHeader[]>(`/api/audiences/${id}/headers`);
};

export const createAudience = (audience: CreateAudienceDto) => {
  return axios.post<Audience>("/api/audiences/", audience);
};

export const deleteAudience = (audiences: DeleteAudienceDto) => {
  if (!audiences.ids.length) return;
  return axios.delete<DeleteAudienceResponse>(`/api/audiences/`, {
    data: audiences,
  });
};

export const importContactsFromCsv = (
  audienceId: number,
  csvFile: File,
  type: ImportedFromSource,
) => {
  const formData = new FormData();
  formData.append("file", csvFile);
  return axios.post(
    `/api/audiences/${audienceId}/import/file?type=${type.toLowerCase()}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const importContactsFromBigQuery = (
  audienceId: number,
  query: ImportBqQueryDto,
) => {
  return axios.post(`/api/audiences/${audienceId}/import/bq`, query);
};

export const exportAudience = (audienceId: number) => {
  return axios.post<any>(`/api/audiences/${audienceId}/download`);
};

export const listContacts = (audienceId: number, page = 1, limit = 10) => {
  return axios.get<Pagination<Contact>>(
    `/api/audiences/${audienceId}/contacts?page=${page}&limit=${limit}`,
  );
};

export const getContactById = (audienceId: number, contactEmail: string) => {
  return axios.get<Contact | null>(
    `/api/audiences/${audienceId}/contacts/${contactEmail}`,
  );
};

export const listCampaignsByAudience = (
  audienceId: number,
  page = 1,
  limit = 10,
) => {
  return axios.get<Pagination<Campaign>>(
    `/api/audiences/${audienceId}/campaigns?page=${page}&limit=${limit}`,
  );
};
