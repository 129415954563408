/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnApi,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  RowClickedEvent,
  RowSelectedEvent,
  SideBarDef,
} from "ag-grid-community";
import { AgGridColumn, AgGridColumnProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import axios from "axios";
import { get } from "lodash";
import { FC, LegacyRef, useCallback, useState } from "react";
import "ag-grid-enterprise";
import { useEffect } from "react";

import { Pagination } from "../types/http";

type TAgGridColumnProps = AgGridColumnProps & {
  id?: string | number;
};

interface IProps extends GridOptions {
  /**
   * Column definitions for grid
   *
   * @required
   * @type {TAgGridColumnProps[]}
   */
  columnDefs: TAgGridColumnProps[];
  /**
   * Row model type for grid, can be one of "clientSide" | "infinite" | "serverSide"
   *
   * @optional
   * @type {String}
   * @default "clientSide"
   */
  rowModelType?: "clientSide" | "infinite" | "serverSide";
  /**
   * Whether to animate rows on loaded
   *
   * @optional
   * @type {Boolean}
   * @default true
   */
  animateRows?: boolean;
  /**
   * Does grid allow select multiple records at the same time?
   *
   * @optional
   * @type {String}
   * @default "single"
   */
  rowSelection?: "single" | "multiple";
  /**
   * HTTP endpoint to load data
   *
   * @required
   * @type {String}
   */
  httpEndpoint?: string;
  /**
   * Whether to enable pagination
   *
   * @optional
   * @type {Boolean}
   * @default false
   */
  pagination?: boolean;
  /**
   * How many items should be loaded for each page
   *
   * @optional
   * @type {Number}
   * @default 10
   */
  paginationPageSize?: number;
  /**
   * Use this to get data if it's returned from server is in nested key
   *
   * @optional
   * @type {String}
   * @default ""
   */
  dataKey?: string;
  /**
   * Sidebar configuration
   *
   * @optional
   * @type {undefined | boolean | string | SideBarDef}
   * @default true
   */
  sideBar?: undefined | boolean | string | SideBarDef;
  /**
   * How many rows to cache in the memory to speed up performance
   *
   * @optional
   * @type {undefined | boolean | string | SideBarDef}
   * @default 10
   */
  cacheBlockSize?: number;
  rowData?: any[];
  idKey?: string;
  gridRef?: LegacyRef<AgGridReact> | undefined;
  onRowClicked?: (event: RowClickedEvent) => void;
  onGridReady?: (event: GridReadyEvent) => void;
  onRowSelected?: (event: RowSelectedEvent) => void;
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
}

const GridComponent: FC<IProps> = ({
  columnDefs,
  rowModelType = "clientSide",
  animateRows = true,
  rowSelection = "single",
  httpEndpoint,
  pagination = false,
  paginationPageSize = 10,
  dataKey = "",
  idKey,
  sideBar = { toolPanels: ["columns", "filters"] },
  cacheBlockSize = 10,
  rowData = [],
  gridRef,
  onRowClicked,
  onRowSelected,
  onFirstDataRendered,
  onGridReady,
  ...restGridProps
}: IProps) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  useEffect(() => {
    if (gridApi) {
      _setDataSource(gridApi);
    }
  }, [httpEndpoint]);

  const _onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    _setDataSource(params.api);
    if (onGridReady) {
      onGridReady(params);
    }
  };

  const _setDataSource = async (api: GridApi) => {
    if (!httpEndpoint) {
      return;
    }
    if (rowModelType === "serverSide") {
      const datasource: IServerSideDatasource = {
        // called by the grid when more rows are required
        getRows: async (params) => {
          params.api.showLoadingOverlay();
          try {
            const response = await axios.get<Pagination<any>>(
              `${httpEndpoint}page=${Math.ceil(
                (params.request.endRow || 0) / paginationPageSize,
              )}&limit=${paginationPageSize}`,
            );
            const data = _getDataFromDataKey(response.data.items);
            params.success({
              rowData: data,
              rowCount: response.data.meta.totalItems,
            });

            if (!data.length) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            setTimeout(() => {
              gridColumnApi?.autoSizeAllColumns();
            }, 1000);
          } catch (error: any) {
            console.error(error);
            params.fail();
            params.api.hideOverlay();
          }
        },
      };
      api.setServerSideDatasource(datasource);
    } else {
      const response = await axios.get<any[]>(httpEndpoint);
      api.setRowData(_getDataFromDataKey(response.data));
    }
  };

  const _getDataFromDataKey = (data: any[]) => {
    let _data = data;
    if (dataKey?.trim().length) {
      _data = data.map((item: any) => {
        const i = get(item, dataKey);
        if (idKey) {
          i.id = get(item, idKey);
        }

        return i;
      });
    }

    return _data;
  };

  const _onRowClicked = (event: RowClickedEvent) => {
    if (onRowClicked) {
      onRowClicked(event);
    }
  };

  const _onRowSelected = (event: RowSelectedEvent) => {
    if (onRowSelected) {
      onRowSelected(event);
    }
  };

  const _onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    if (onFirstDataRendered) {
      onFirstDataRendered(event);
    }
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.id;
  }, []);

  return (
    <AgGridReact
      defaultColDef={{
        minWidth: 90,
        resizable: true,
      }}
      getRowId={getRowId}
      rowModelType={rowModelType}
      animateRows={animateRows}
      rowSelection={rowSelection}
      pagination={pagination}
      paginationPageSize={paginationPageSize}
      serverSideStoreType={"partial"}
      cacheBlockSize={cacheBlockSize}
      sideBar={sideBar}
      rowData={rowData}
      onGridReady={_onGridReady}
      onRowClicked={_onRowClicked}
      onRowSelected={_onRowSelected}
      onFirstDataRendered={_onFirstDataRendered}
      ref={gridRef}
      {...restGridProps}
    >
      {columnDefs.map((item, index) => (
        <AgGridColumn key={item.id || index} {...item}></AgGridColumn>
      ))}
    </AgGridReact>
  );
};

export default GridComponent;
