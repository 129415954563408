import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { useEffect, useRef } from "react";
import { ChangeEvent, MouseEvent, useState } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import theme from "../common/theme";
import { CustomTable } from "../components/CustomTable";
import { RenameDialog } from "../components/Dialog/RenameDialog";
import PageLayout from "../components/Layout/PageLayout";
import { useToast } from "../contexts/ToastContext";
import useQuery from "../hooks/useQuery";
import { saveCampaign } from "../http/campaign";
import {
  deleteTemplateById,
  listTemplates,
  replicateTemplate,
  updateTemplate,
} from "../http/template";
import { PaginationMeta } from "../types/http";
import { Template } from "../types/template";

const useStyles = makeStyles(() => ({
  templateName: {
    // padding: theme.spacing(4, 0),
    fontSize: 24,
  },
  lastEdit: {
    fontSize: 16,
  },
  type: {
    fontSize: 16,
  },
  codemirror: {
    fontSize: "0.875rem",
  },
  templateImg: {
    marginRight: theme.spacing(2),
    height: 100,
    border: "1px solid #dedddc",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 90,
  },
}));

export default function TemplateList() {
  const classes = useStyles();
  const query = useQuery();
  const [pageMeta, setPageMeta] = useState<PaginationMeta>({
    currentPage: parseInt(query.get("page") || "1"),
    itemCount: 0,
    itemsPerPage: parseInt(query.get("limit") || "10"),
    totalItems: 0,
    totalPages: 0,
  });
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const [openExportTemplate, setOpenExportTemplate] = useState<boolean>(false);
  const [exportedContent, setExportedContent] = useState<string>("");
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState<boolean>(false);
  const [openRenameTemplate, setOpenRenameTemplate] = useState<boolean>(false);

  const navigate = useNavigate();
  const { openToast } = useToast();

  // Search
  const templateNameRef = useRef<string>("");
  const [name, setName] = useState<string>(query.get("name") || "");
  // End search

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    let search = `?page=${pageMeta.currentPage}&limit=${pageMeta.itemsPerPage}`;
    if (name.trim().length) {
      search += `&name=${name}`;
    }

    navigate({
      pathname: "/templates/list",
      search,
    });

    loadTemplates();
  }, [pageMeta.currentPage, pageMeta.itemsPerPage, name]);

  const loadTemplates = async () => {
    try {
      const response = await listTemplates(
        name,
        pageMeta.currentPage,
        pageMeta.itemsPerPage,
      );
      setPageMeta(response.data.meta);
      setTemplates(response.data.items);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
    }
  };

  const search = () => {
    setName(templateNameRef.current);
  };

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPageMeta({ ...pageMeta, currentPage: page + 1 });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageMeta({
      ...pageMeta,
      currentPage: 1,
      itemsPerPage: parseInt(event.target.value, 10),
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (
    event: MouseEvent<HTMLButtonElement>,
    row: Template,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onExport = () => {
    handleCloseMenu();
    if (!selectedTemplate) return;
    setExportedContent(selectedTemplate.html);
    setOpenExportTemplate(true);
  };

  const handleExportTemplate = () => {
    if (!selectedTemplate) return;
    const data = new Blob([exportedContent], { type: "text/html" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = `${selectedTemplate.name}.html`;
    link.click();
  };

  const onReplicate = async () => {
    handleCloseMenu();
    try {
      const response = await replicateTemplate(selectedTemplate?.id || 0);
      navigate(`/templates/edit/${response.data.id}`);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
      console.error(error);
    }
  };

  const onDeleteTemplate = async () => {
    handleCloseMenu();
    if (!selectedTemplate) return;
    try {
      await deleteTemplateById(selectedTemplate.id);
      await loadTemplates();
      openToast(
        "success",
        `Successfully delete template ${selectedTemplate.name}`,
      );
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
    }
  };

  const onOpenDeleteTemplate = () => {
    handleCloseMenu();
    setOpenDeleteTemplate(true);
  };
  const onCloseDeleteTemplate = () => setOpenDeleteTemplate(false);
  const onSubmitDeletion = () => {
    onDeleteTemplate();
    onCloseDeleteTemplate();
  };

  const onRenameTemplate = async (newName: string) => {
    handleCloseMenu();
    if (!selectedTemplate) return;
    try {
      await updateTemplate(selectedTemplate.id, {
        name: newName,
      });
      setTemplates(
        templates.map((template) =>
          template.id === selectedTemplate.id
            ? { ...template, name: newName }
            : template,
        ),
      );
      openToast("success", "Successfully renamed template");
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
    }
  };

  const onOpenRenameTemplate = () => {
    handleCloseMenu();
    setOpenRenameTemplate(true);
  };
  const onCloseRenameTemplate = () => setOpenRenameTemplate(false);
  const onSubmitRename = (newName: string) => {
    onRenameTemplate(newName);
    onCloseRenameTemplate();
  };

  const onCreateCampaign = async () => {
    handleCloseMenu();
    try {
      const response = await saveCampaign({
        name: "My Campaign",
        templateId: selectedTemplate?.id || 0,
      });
      navigate(`/campaigns/edit/${response.data.id}`);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
      console.error(error);
    }
  };
  const tableHeaders = ["Name", "Actions"];
  return (
    <PageLayout>
      <Box sx={{ display: "flex", marginBottom: theme.spacing(3) }}>
        <Typography variant="h4" style={{ flex: 1 }}>
          Templates
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/templates/create"
        >
          Create Template
        </Button>
      </Box>
      <Stack direction="row" mb={3}>
        <TextField
          inputRef={(e) => {
            if (e) {
              e.value = name;
              e.focus();
            }
          }}
          label="Name"
          onChange={(e) => {
            templateNameRef.current = e.target.value;
          }}
          variant="outlined"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              search();
            }
          }}
          sx={{ minWidth: 300 }}
          size="small"
        />
        <Button variant="contained" sx={{ ml: 4 }} onClick={() => search()}>
          Search
        </Button>
      </Stack>
      <CustomTable
        tableHeads={
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        }
        tableBody={
          <>
            {templates.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={classes.templateImg}
                      style={{
                        backgroundImage: `url(${
                          row.preview ||
                          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAJFBMVEX4+vvb4OTx9PXl6ezf4+f19/nt8PLz9vfq7fDi5unc4eTs7/GWhAgrAAACGUlEQVR4nO3b23KqMBiAUQEtsn3/961OtydIJEUmaXCtS7n5v5FTEHc7AAAAAAAAAAAAAAAAAAAAAAAAzvr2PX3pgNf6oXnf8Icjjyv0XZxKh8R8rRTYNF+lU8IOqwU2zaF0TNBa++jFsXRM0H7Fwn3pmKAVA5umdEzQhxXuT/3h0B7/bbbwdrpfevopGRIVnq/dZOHT1WzZfUCpiJdu040uZovuVcskzIhNt2g/LVIw5zrcENuwmcLJHdeS3bREwKzrcJO1z+YKJ99h/IZ138bOtCUCZl2HSz8OL/fXkTVXgfnnxaaLnkt/FhDhxPzjJ7hNNzoQY4fhdYUUTMw+fYr7eEn3NPclYCgx+/QpwvPF9tHHNW4gMffwSR4HbK8fxtYWz4v4aWL26VM8F5z68/cXXTuNn1JMEosUzInVJAROEwvMP++dwEli9ulTvBU4Tsw8e5r3AkeJWSdPFaoZ+lNq4HNixrnTBQK73eSC+OpZ7+H+aC7X0L8SDhwlvn6Y3VVW2P3f0KYG1lbY3ba0iYGVFXYPm9q0wLoKu6dtbVJgVYXdaGOb9ItZRYXjwPMyMeUnwXoKp4FpqilcGlhN4eRhW7JaChd/hQpLU5hAYWEfVDgsfrl0qKRwDaVjghTWX7j9dxO3/37pmrtp6ZSIzb/nvdtNnm8v9Gff1f+A/1tcbPw/MwAAAAAAAAAAAAAAAAAAAAAAALl8A7+jEA62Pbx2AAAAAElFTkSuQmCC"
                        })`,
                      }}
                    ></div>
                    <Box>
                      <Link
                        component={RouterLink}
                        to={`/templates/edit/${row.id}`}
                        underline="none"
                        className={classes.templateName}
                      >
                        {row.name}
                      </Link>
                      <div className={classes.lastEdit}>
                        Last edited: on{" "}
                        {moment(row.updatedAt).format("YYYY-MM-DD HH:mm A")} by{" "}
                        <b>{row.updatedByProfile?.fullname || ""}</b>
                      </div>
                      <div className={classes.type}>Classic</div>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      onClick={() => navigate(`/templates/edit/${row.id}`)}
                    >
                      Edit
                    </Button>
                    <Button
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(e) => handleClickMenu(e, row)}
                    >
                      <KeyboardArrowDownIcon />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </>
        }
        pageMeta={pageMeta}
        amountColumns={tableHeaders.length}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { mt: 0.5 } }}
      >
        <MenuItem onClick={onCreateCampaign}>Create campaign</MenuItem>
        <MenuItem onClick={handleCloseMenu}>Preview</MenuItem>
        <MenuItem onClick={onExport}>Export as HTML</MenuItem>
        <MenuItem onClick={onReplicate}>Replicate</MenuItem>
        <MenuItem onClick={onOpenRenameTemplate}>Rename</MenuItem>
        <MenuItem onClick={onOpenDeleteTemplate}>Delete</MenuItem>
      </Menu>
      {selectedTemplate && (
        <RenameDialog
          open={openRenameTemplate}
          object="template"
          oldName={selectedTemplate.name}
          handleClose={onCloseRenameTemplate}
          handleSubmit={onSubmitRename}
        />
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        scroll="paper"
        open={openExportTemplate}
        onClose={onCloseDeleteTemplate}
      >
        <DialogTitle>Export template as HTML</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              pb: 1,
            }}
          >
            HTML source
          </DialogContentText>
          <CodeMirror
            className={classes.codemirror}
            value={exportedContent || ""}
            options={{
              mode: "xml",
              theme: "material",
              lineNumbers: true,
            }}
            onChange={(_editor, _data, value) => setExportedContent(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setOpenExportTemplate(false)}
          >
            Close
          </Button>
          <Button onClick={handleExportTemplate}>Export</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteTemplate}
        onClose={onCloseDeleteTemplate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deletion confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="div">
            <Typography>
              Are you sure to delete the following template?
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", pt: 1 }}
            >
              {selectedTemplate?.name}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onCloseDeleteTemplate}>
            Cancel
          </Button>
          <Button onClick={onSubmitDeletion} autoFocus>
            Go ahead
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}
