import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ColDef } from "ag-grid-community";
import { FC } from "react";

import theme from "../../common/theme";
import { Campaign } from "../../types/campaign";
import GridComponent from "../GridComponent";

interface IProps {
  campaign: Campaign;
  opened: boolean;
}

const Opened: FC<IProps> = ({ campaign, opened }: IProps) => {
  const columnDefs: ColDef[] = campaign.audienceHeaders
    ? campaign.audienceHeaders.map((item) => {
        return {
          field: item.header,
          pinned: item.header === "email" ? "left" : undefined,
          headerName: item.header,
        };
      })
    : [];
  return (
    <>
      <Typography variant="h4" sx={{ flex: 1, mt: theme.spacing(2) }}>
        {opened ? "Opened" : "Didn't Opened"}
      </Typography>
      <Box
        className="ag-theme-alpine"
        sx={{ width: "100%", height: 500, mt: theme.spacing(3) }}
      >
        <GridComponent
          columnDefs={columnDefs}
          rowModelType={"serverSide"}
          httpEndpoint={`/api/reports/${campaign.id}/contact-open-tracking?opened=${opened}&`}
          pagination={true}
          dataKey={"data"}
          idKey={"id"}
        />
      </Box>
    </>
  );
};

export default Opened;
