import CampaignIcon from "@mui/icons-material/Campaign";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ImageIcon from "@mui/icons-material/Image";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { ReactNode } from "react";

import theme from "../common/theme";

export interface BaseRoute {
  name: string;
  to: string;
  iconSelected?: ReactNode;
  iconUnselected?: ReactNode;
}
interface Route {
  base: BaseRoute;
  sub: BaseRoute[];
}
[];

export const routes: Route[] = [
  {
    base: {
      name: "Audiences",
      to: "/audiences",
      iconSelected: <PeopleAltIcon sx={{ fontSize: theme.spacing(4) }} />,
      iconUnselected: (
        <PeopleAltOutlinedIcon sx={{ fontSize: theme.spacing(4) }} />
      ),
    },
    sub: [
      {
        name: "Dashboard",
        to: "/audiences/dashboard",
      },
      {
        name: "All audiences",
        to: "/audiences/list",
      },
      {
        name: "Contacts",
        to: "/audiences/view",
      },

      {
        name: "Create new audience",
        to: "/audiences/create",
      },
    ],
  },
  {
    base: {
      name: "Templates",
      to: "/templates",
      iconSelected: <ImageIcon sx={{ fontSize: theme.spacing(4) }} />,
      iconUnselected: <ImageOutlinedIcon sx={{ fontSize: theme.spacing(4) }} />,
    },
    sub: [
      {
        name: "All templates",
        to: "/templates/list",
      },
      {
        name: "Create new template",
        to: "/templates/create",
      },
    ],
  },
  {
    base: {
      name: "Campaigns",
      to: "/campaigns",
      iconSelected: <CampaignIcon sx={{ fontSize: theme.spacing(4) }} />,
      iconUnselected: (
        <CampaignOutlinedIcon sx={{ fontSize: theme.spacing(4) }} />
      ),
    },
    sub: [
      {
        name: "All campaigns",
        to: "/campaigns/list",
      },
      {
        name: "Reports",
        to: "/campaigns/reports",
      },
      {
        name: "Create new campaign",
        to: "/campaigns/create",
      },
    ],
  },
  {
    base: {
      name: "Search",
      to: "/search",
      iconSelected: <ManageSearchIcon sx={{ fontSize: theme.spacing(4) }} />,
      iconUnselected: <ManageSearchIcon sx={{ fontSize: theme.spacing(4) }} />,
    },
    sub: [
      {
        name: "Search Email",
        to: "/search",
      },
    ],
  },
];
