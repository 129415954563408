import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import theme from "../../common/theme";
import { ImportedFromSource } from "../../constants/audience";
import { Audience } from "../../types/audience";

const useStyles = makeStyles({
  formLabel: {
    padding: theme.spacing(1, 0),
  },
});

export const ChooseImportMethod = ({
  audience,
  method,
  onChangeImportMethod,
  handleNextStep,
}: {
  audience: Audience | null;
  method: string;
  onChangeImportMethod: (event: ChangeEvent<HTMLInputElement>) => void;
  handleNextStep: () => void;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const defaultValue = Object.values(ImportedFromSource).find(
    (value) => value === method,
  );
  const defaultMethod = audience?.source;

  return (
    <>
      <Box sx={{ minHeight: "300px" }}>
        <Typography variant="h5" sx={{ pb: 3, pt: 1 }}>
          How would you like to import your contacts?
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="import-contacts"
            defaultValue={defaultValue || ""}
            value={method || ""}
            onChange={onChangeImportMethod}
          >
            <FormControlLabel
              disabled={
                !!defaultMethod &&
                !(
                  defaultMethod === ImportedFromSource.CSV ||
                  defaultMethod === ImportedFromSource.TEXT
                )
              }
              className={classes.formLabel}
              value={ImportedFromSource.CSV}
              control={<Radio />}
              label={
                <>
                  <Typography>Upload file</Typography>
                  <FormHelperText id="import-file-helper-text" sx={{ m: 0 }}>
                    Upload a CSV or tab-delimited TXT file.
                  </FormHelperText>
                </>
              }
            />
            <FormControlLabel
              disabled={
                !!defaultMethod &&
                !(
                  defaultMethod === ImportedFromSource.CSV ||
                  defaultMethod === ImportedFromSource.TEXT
                )
              }
              className={classes.formLabel}
              value={ImportedFromSource.TEXT}
              control={<Radio />}
              label={
                <>
                  <Typography>Copy and Paste </Typography>
                  <FormHelperText id="import-text-helper-text" sx={{ m: 0 }}>
                    Copy and paste contacts from a spreadsheet.
                  </FormHelperText>
                </>
              }
            />
            <FormControlLabel
              disabled={
                !!defaultMethod &&
                defaultMethod !== ImportedFromSource.BIG_QUERY
              }
              className={classes.formLabel}
              value={ImportedFromSource.BIG_QUERY}
              control={<Radio />}
              label={
                <>
                  <Typography>Big Query</Typography>
                  <FormHelperText
                    id="import-big-query-helper-text"
                    sx={{ m: 0 }}
                  >
                    Fetch data from Big Query.
                  </FormHelperText>
                </>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button disabled={!method} onClick={handleNextStep}>
          Next
        </Button>
      </Box>
    </>
  );
};
