import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles(() => ({
  dragDrop: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#2196f3",
    },
  },
}));

export const CsvUpload = ({
  file,
  onChangeFile,
}: {
  file: File | null;
  onChangeFile: (file: File | null) => void;
}) => {
  const classes = useStyles();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".csv, text/csv, text/comma-separated-values, application/csv",
    maxFiles: 1,
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      onChangeFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const formatBytes = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  };

  // const onChangeInputFile = (event: ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (!files || files.length < 1) return;
  //   onChangeFile(files[0]);
  // };

  // const Input = styled("input")({
  //   display: "none",
  // });

  return (
    <>
      <Typography variant="h5" sx={{ pb: 5, pt: 1 }}>
        Select a CSV file which must have an <strong>email</strong> field
      </Typography>
      {file ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography>{file.name}</Typography>
            <Typography>{formatBytes(file.size)}</Typography>
          </Box>
          <Button
            variant="contained"
            component="span"
            size="large"
            endIcon={<CancelIcon />}
            disableElevation
            sx={{
              p: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            onClick={() => onChangeFile(null)}
          >
            Cancel
          </Button>
        </Stack>
      ) : (
        <div className={classes.dragDrop} {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag n drop some files here, or click to select files</p>
        </div>
      )}
    </>
  );
};
