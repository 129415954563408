import axios from "axios";

import { Pagination } from "../types/http";
import { Image } from "../types/image";

export const listImages = (page = 1, limit = 10) => {
  return axios.get<Pagination<Image>>(
    `/api/images?page=${page}&limit=${limit}`,
  );
};
