import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { SyntheticEvent, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import theme from "../common/theme";
import { CustomTabPanel } from "../components/CustomTabPanel/CustomTabPanel";
import PageLayout from "../components/Layout/PageLayout";
import { saveTemplate } from "../http/template";

const useStyles = makeStyles(() => ({
  templateImg: {
    border: "solid 1px #ddd",
    borderRadius: 3,
    borderWidth: 2,
    cursor: "pointer",
    width: "100%",
    transition: "border-color .2s",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function TemplateSelector() {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const createTemplate = async () => {
    try {
      const response = await saveTemplate({
        name: "My template",
      });

      navigate(`/templates/edit/${response.data.id}`);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <PageLayout>
      <Typography variant="h4" style={{ flex: 1 }}>
        Select a starting point for your template
      </Typography>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: theme.spacing(3),
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Layouts" />
          <Tab label="Themes" />
          <Tab label="Code your own" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Typography
          variant="h6"
          style={{ flex: 1, marginTop: theme.spacing(2) }}
        >
          Features
        </Typography>
        <Grid container spacing={2}>
          {Array.from(Array(6)).map((_, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box sx={{ textAlign: "center", padding: theme.spacing(1) }}>
                <Link underline="none" onClick={createTemplate}>
                  <img
                    src="https://cdn-images.mailchimp.com/template_screenshots/int-sell-products.png"
                    className={classes.templateImg}
                  />
                </Link>

                <b>Sell Products</b>
                <div>Market a line of products or promote seasonal items.</div>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="h6"
          style={{ flex: 1, marginTop: theme.spacing(3) }}
        >
          Basic
        </Typography>
        <Grid container spacing={2}>
          {Array.from(Array(6)).map((_, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box sx={{ textAlign: "center", padding: theme.spacing(1) }}>
                <Link underline="none" onClick={createTemplate}>
                  <img
                    src="https://cdn-images.mailchimp.com/template_screenshots/1_column_updated.svg"
                    className={classes.templateImg}
                  />
                </Link>
                <b>1 Column</b>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        In progress
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Box sx={{ textAlign: "center", padding: theme.spacing(1) }}>
              <Link
                component={RouterLink}
                to="/templates/edit"
                underline="none"
              >
                <img
                  src="https://us17.admin.mailchimp.com/images/templates/code-your-own.svg"
                  className={classes.templateImg}
                />
              </Link>

              <b>Paste in code</b>
              <div>Create a template by pasting your custom coded design.</div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Box sx={{ textAlign: "center", padding: theme.spacing(1) }}>
              <Link
                component={RouterLink}
                to="/templates/edit"
                underline="none"
              >
                <img
                  src="https://us17.admin.mailchimp.com/images/templates/import-from-file.svg"
                  className={classes.templateImg}
                />
              </Link>

              <b>Import HTML</b>
              <div>
                Create a template by uploading an HTML file with your template
                code.
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Box sx={{ textAlign: "center", padding: theme.spacing(1) }}>
              <Link
                component={RouterLink}
                to="/templates/edit"
                underline="none"
              >
                <img
                  src="https://us17.admin.mailchimp.com/images/templates/import-from-file.svg"
                  className={classes.templateImg}
                />
              </Link>

              <b>Import zip</b>
              <div>
                Create a template by uploading a zip file with your template
                code.
              </div>
            </Box>
          </Grid>
        </Grid>
      </CustomTabPanel>
    </PageLayout>
  );
}
