import { Campaign } from "./campaign";
import { Contact } from "./contact";

export interface Job {
  id: number;
  campaignId: string;
  campaign?: Campaign;
  contactId: string;
  contact?: Contact;
  status: string;
  errorCode: string;
  clicked: boolean;
  opened: boolean;
}

export enum JobStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  HARD_BOUNCED = "HARD_BOUNCED",
  SOFT_BOUNCED = "SOFT_BOUNCED",
}
