import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getContactById } from "../../http/audience";
import { Contact } from "../../types/contact";
import PageLayout from "../Layout/PageLayout";
import { TextAvatar } from "../TextAvatar/TextAvatar";

export const ContactDetail = () => {
  const [contact, setContact] = useState<Contact | null>(null);
  const { audienceId, contactEmail } = useParams<{
    audienceId: string;
    contactEmail: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    let active = true;
    (async () => {
      try {
        if (!audienceId || !contactEmail) {
          return;
        }
        const response = await getContactById(
          parseInt(audienceId),
          contactEmail,
        );
        const contact = response.data;
        if (active) {
          setContact(contact);
        }
      } catch (error: any) {
        console.error(error.response?.data.message || error.message);
      }
    })();

    return () => {
      active = false;
    };
  }, [audienceId, contactEmail]);

  return (
    <PageLayout>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {contact ? (
          <Box>
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextAvatar displayedName={contact.email} />
              <Box
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >
                <Typography variant="h5" sx={{ pb: 1 }}>
                  {contact.email}
                </Typography>
                <Typography>
                  Created at{" "}
                  {moment(contact.createdAt).format("YYYY-MM-DD HH:mm:ss A")}
                </Typography>
                <Typography>
                  Last updated at{" "}
                  {moment(contact.updatedAt).format("YYYY-MM-DD HH:mm:ss A")}
                </Typography>
              </Box>
            </Stack>
            {Object.keys(contact.data).map((key) => (
              <Box key={key} sx={{ py: 2 }}>
                <Typography sx={{ pb: 1 }}>{key}</Typography>
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  value={contact.data[key]}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Typography variant="h5">Contact Not Found</Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </Container>
    </PageLayout>
  );
};
