import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { CURRENT_TIMEZONE_OFFSET } from "../common/constants";
import theme from "../common/theme";
import { CustomTable } from "../components/CustomTable";
import { RenameDialog } from "../components/Dialog/RenameDialog";
import PageLayout from "../components/Layout/PageLayout";
import { useToast } from "../contexts/ToastContext";
import useQuery from "../hooks/useQuery";
import {
  deleteCampaignById,
  listCampaigns,
  pauseCampaign,
  replicateCampaign,
  saveCampaign,
  updateCampaign,
} from "../http/campaign";
import { Campaign, CAMPAIGN_STATUS } from "../types/campaign";
import { PaginationMeta } from "../types/http";

function ScheduleCountDown({ time }: { time: number }) {
  const [count, setCount] = useState<number>(
    time > 0 ? parseInt(time.toString()) : 0,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (count >= 1) {
        setCount((c) => c - 1);
      } else {
        setCount(0);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);
  return (
    <Chip
      icon={<AccessTimeIcon />}
      label={moment.utc(count * 1000).format("HH:mm:ss") + " before launch"}
      variant="outlined"
    />
  );
}

const useStyles = makeStyles(() => ({
  templateName: {
    // padding: theme.spacing(4, 0),
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 20,
    transition: "font-weight .15s",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  lastEdit: {
    fontSize: 16,
  },
  type: {
    fontSize: 16,
  },
  templateImg: {
    marginRight: theme.spacing(2),
    height: 100,
    border: "1px dashed grey",
  },
  tableRow: {
    transition: "background-color .15s",
    "&:hover": {
      backgroundColor: "#c0c0c033",
      "& $rate": {
        display: "none",
      },
      "& $rateValue": {
        display: "initial",
      },
      "& $templateName": {
        fontWeight: "bold",
      },
    },
  },
  rate: {},
  rateValue: {
    display: "none",
  },
}));

export default function CampaignList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { openToast } = useToast();
  const query = useQuery();
  const [pageMeta, setPageMeta] = useState<PaginationMeta>({
    currentPage: parseInt(query.get("page") || "1"),
    itemCount: 0,
    itemsPerPage: parseInt(query.get("limit") || "10"),
    totalItems: 0,
    totalPages: 0,
  });
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null,
  );
  const [openDeleteCampaign, setOpenDeleteCampaign] = useState<boolean>(false);
  const [openRenameCampaign, setOpenRenameCampaign] = useState<boolean>(false);
  const [isScheduleWarningVisible, setIsScheduleWarningVisible] =
    useState<boolean>(false);

  // Search
  const campaignNameRef = useRef<string>("");
  const [name, setName] = useState<string>(query.get("name") || "");
  // End search

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    let search = `?page=${pageMeta.currentPage}&limit=${pageMeta.itemsPerPage}`;
    if (name.trim().length) {
      search += `&name=${name}`;
    }

    navigate({
      pathname: "/campaigns/list",
      search,
    });

    loadCampaigns();
  }, [pageMeta.currentPage, pageMeta.itemsPerPage, name]);

  const loadCampaigns = async () => {
    try {
      const response = await listCampaigns(
        name,
        pageMeta.currentPage,
        pageMeta.itemsPerPage,
      );
      setPageMeta(response.data.meta);
      setCampaigns(response.data.items);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
    }
  };

  const search = () => {
    setName(campaignNameRef.current);
  };

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPageMeta({ ...pageMeta, currentPage: page + 1 });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageMeta({
      ...pageMeta,
      currentPage: 1,
      itemsPerPage: parseInt(event.target.value, 10),
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (
    event: MouseEvent<HTMLButtonElement>,
    row: Campaign,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedCampaign(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const createCampaign = async () => {
    try {
      const response = await saveCampaign({
        name: "My Campaign",
      });
      navigate(`/campaigns/edit/${response.data.id}`);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
      console.error(error);
    }
  };

  const renderStatus = (row: Campaign) => {
    switch (row.status) {
      case CAMPAIGN_STATUS.SCHEDULED: {
        if (!row.schedule) {
          return null;
        }

        const { year, month, day, hour, minute, gmt } = row.schedule;
        const target = moment.utc([year, month, day, hour, minute]);
        const curDate = new Date();
        const curDateM = moment.utc([
          curDate.getFullYear(),
          curDate.getMonth(),
          curDate.getDate(),
          curDate.getHours(),
          curDate.getMinutes(),
        ]);
        const duration = moment.duration(target.diff(curDateM));
        const tzDiff = CURRENT_TIMEZONE_OFFSET - gmt;
        const time = duration.asSeconds() + tzDiff * 3600;
        return (
          <>
            <Chip
              label={`SCHEDULED AT: ${target.format(
                "YYYY-MM-DD HH:mm:ss A",
              )} (GMT: ${gmt ? "+" : "-"}${gmt})`}
              color="secondary"
              sx={{ marginBottom: 1 }}
            />
            <br />
            <ScheduleCountDown time={time} />
          </>
        );
      }
      case CAMPAIGN_STATUS.PAUSED: {
        return <Chip label={row.status} color="warning" />;
      }
      case CAMPAIGN_STATUS.SENT: {
        return <Chip label={row.status} color="success" />;
      }
      case CAMPAIGN_STATUS.ERROR: {
        return (
          <Tooltip title={row.errorMessage || ""}>
            <Chip
              icon={<InfoIcon />}
              label={row.status}
              color="error"
              variant="outlined"
            />
          </Tooltip>
        );
      }
      default: {
        return <Chip label={row.status} />;
      }
    }
  };

  const onReplicate = async () => {
    handleCloseMenu();
    try {
      const response = await replicateCampaign(selectedCampaign?.id || 0);
      navigate(`/campaigns/edit/${response.data.id}`);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
      console.error(error);
    }
  };

  const onDeleteCampaign = async () => {
    handleCloseMenu();
    if (!selectedCampaign) return;
    try {
      await deleteCampaignById(selectedCampaign.id);
      await loadCampaigns();
      openToast(
        "success",
        `Successfully delete campaign ${selectedCampaign.name}`,
      );
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
    }
  };

  const onOpenDeleteCampaign = () => {
    handleCloseMenu();
    setOpenDeleteCampaign(true);
  };
  const onCloseDeleteCampaign = () => setOpenDeleteCampaign(false);
  const onSubmitDeletion = () => {
    onDeleteCampaign();
    onCloseDeleteCampaign();
  };

  const onRenameCampaign = async (newName: string) => {
    handleCloseMenu();
    if (!selectedCampaign) return;
    try {
      await updateCampaign(selectedCampaign.id, {
        name: newName,
      });
      setCampaigns(
        campaigns.map((campaign) =>
          campaign.id === selectedCampaign.id
            ? { ...campaign, name: newName }
            : campaign,
        ),
      );
      openToast("success", "Successfully renamed template");
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
    }
  };

  const onOpenRenameCampaign = () => {
    handleCloseMenu();
    setOpenRenameCampaign(true);
  };

  const onViewEmail = () => {
    handleCloseMenu();
    navigate(`/campaigns/show/${selectedCampaign?.id}`);
  };

  const onCloseRenameCampaign = () => setOpenRenameCampaign(false);

  const onSubmitRename = (newName: string) => {
    onRenameCampaign(newName);
    onCloseRenameCampaign();
  };

  const _pause = async (row: Campaign) => {
    try {
      await pauseCampaign(row.id);
      navigate(`/campaigns/edit/${row.id}`);
    } catch (error: any) {
      console.error(error);
    }
  };

  const renderActionButton = (row: Campaign) => {
    switch (row.status) {
      case CAMPAIGN_STATUS.SCHEDULED: {
        return <Button onClick={() => _pause(row)}>PAUSE AND EDIT</Button>;
      }
      case CAMPAIGN_STATUS.SENT: {
        return (
          <Button onClick={() => navigate(`/campaigns/reports/${row.id}`)}>
            View report
          </Button>
        );
      }
      default: {
        return (
          <Button onClick={() => navigate(`/campaigns/edit/${row.id}`)}>
            Edit
          </Button>
        );
      }
    }
  };

  const _selectCampaign = (row: Campaign) => {
    if (row.status === CAMPAIGN_STATUS.SCHEDULED) {
      setSelectedCampaign(row);
      setIsScheduleWarningVisible(true);
    } else {
      navigate(
        `/campaigns/${row.status === CAMPAIGN_STATUS.SENT ? "show" : "edit"}/${
          row.id
        }`,
      );
    }
  };

  return (
    <PageLayout>
      <Box sx={{ display: "flex", marginBottom: theme.spacing(3) }}>
        <Typography variant="h4" style={{ flex: 1 }}>
          Campaigns
        </Typography>
        <Button variant="contained" onClick={createCampaign}>
          Create Campaign
        </Button>
      </Box>
      <Stack direction="row" mb={3}>
        <TextField
          inputRef={(e) => {
            if (e) {
              e.value = name;
              e.focus();
            }
          }}
          label="Name"
          onChange={(e) => {
            campaignNameRef.current = e.target.value;
          }}
          variant="outlined"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              search();
            }
          }}
          sx={{ minWidth: 300 }}
          size="small"
        />
        <Button variant="contained" sx={{ ml: 4 }} onClick={() => search()}>
          Search
        </Button>
      </Stack>
      <CustomTable
        tableHeads={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Open rate</TableCell>
            <TableCell>Click Rate</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        }
        tableBody={
          <>
            {campaigns.map((row) => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell>
                  <Box>
                    <div
                      className={classes.templateName}
                      onClick={() => _selectCampaign(row)}
                    >
                      {row.name}
                    </div>
                    {/* <Link
                      component={RouterLink}
                      to={`/campaigns/${
                        row.status === CAMPAIGN_STATUS.SENT ? "show" : "edit"
                      }/${row.id}`}
                      underline="none"
                      className={classes.templateName}
                    >
                      
                    </Link> */}
                    <div className={classes.lastEdit}>
                      {row.audienceId && (
                        <>
                          To:{" "}
                          <Link
                            component={RouterLink}
                            to={`/audiences/view/${row.audienceId}`}
                            underline="none"
                            className={classes.templateName}
                            style={{ fontSize: 14 }}
                          >
                            {row.audienceName}
                          </Link>
                        </>
                      )}
                      {row.status === CAMPAIGN_STATUS.SENT && (
                        <>
                          <br />
                          Sent:{" "}
                          {moment(row.deliveryDate).format(
                            "YYYY-MM-DD HH:mm:ss A",
                          )}{" "}
                          to <b>{row.jobsSent} recipients</b>
                        </>
                      )}
                      <br />
                      Last edited: on{" "}
                      {moment(row.updatedAt).format(
                        "YYYY-MM-DD HH:mm A",
                      )} by <b>{row.updatedByProfile?.fullname || ""}</b>
                    </div>
                  </Box>
                </TableCell>
                <TableCell>{renderStatus(row)}</TableCell>
                <TableCell>
                  <div className={classes.rate}>
                    {row.status === CAMPAIGN_STATUS.SENT && (
                      <b>
                        {(
                          ((row.uniqueOpens || 0) / (row.jobsSent || 1)) *
                          100
                        ).toFixed(1)}
                        %
                      </b>
                    )}
                  </div>
                  <div className={classes.rateValue}>
                    <b>{row.uniqueOpens}</b>
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.rate}>
                    {row.status === CAMPAIGN_STATUS.SENT && (
                      <b>
                        {(
                          ((row.uniqueClicks || 0) / (row.jobsSent || 1)) *
                          100
                        ).toFixed(1)}
                        %
                      </b>
                    )}
                  </div>
                  <div className={classes.rateValue}>
                    <b>{row.uniqueClicks}</b>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    {renderActionButton(row)}
                    <Button
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(e) => handleClickMenu(e, row)}
                    >
                      <KeyboardArrowDownIcon />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </>
        }
        pageMeta={pageMeta}
        amountColumns={5}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { mt: 0.5 } }}
      >
        {selectedCampaign?.templateId && (
          <MenuItem onClick={onViewEmail}>View email</MenuItem>
        )}
        <MenuItem onClick={onOpenRenameCampaign}>Rename</MenuItem>
        <MenuItem onClick={onReplicate}>Replicate</MenuItem>
        <MenuItem onClick={onOpenDeleteCampaign}>Delete</MenuItem>
      </Menu>
      {selectedCampaign && (
        <RenameDialog
          open={openRenameCampaign}
          object="template"
          oldName={selectedCampaign.name}
          handleClose={onCloseRenameCampaign}
          handleSubmit={onSubmitRename}
        />
      )}
      <Dialog
        open={openDeleteCampaign}
        onClose={onCloseDeleteCampaign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deletion confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="div">
            <Typography>
              Are you sure to delete the following campaign?
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", pt: 1 }}
            >
              {selectedCampaign?.name}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onCloseDeleteCampaign}>
            Cancel
          </Button>
          <Button onClick={onSubmitDeletion} autoFocus>
            Go ahead
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isScheduleWarningVisible}
        onClose={() => setIsScheduleWarningVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You need to pause your campaign before can continue editing
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsScheduleWarningVisible(false)}>
            Close
          </Button>
          <Button
            color="error"
            onClick={() => selectedCampaign && _pause(selectedCampaign)}
            autoFocus
          >
            Pause And Edit
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}
