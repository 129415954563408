import axios from "axios";

import { Pagination, SaveTemplateDTO } from "../types/http";
import { Template } from "../types/template";

export const saveTemplate = (body: SaveTemplateDTO) => {
  return axios.post<Template>("/api/templates", body);
};

export const updateTemplate = (id: number, body: SaveTemplateDTO) => {
  return axios.put(`/api/templates/${id}`, body);
};

export const listTemplates = (name = "", page = 1, limit = 10) => {
  let url = `/api/templates?page=${page}&limit=${limit}`;
  if (name.trim().length) {
    url += `&name=${name}`;
  }
  return axios.get<Pagination<Template>>(url);
};

export const getTemplateById = (id: number) => {
  return axios.get<Template>(`/api/templates/${id}`);
};

export const replicateTemplate = (id: number) => {
  return axios.post<Template>(`/api/templates/${id}/replicate`);
};

export const deleteTemplateById = (id: number) => {
  return axios.delete<boolean>(`/api/templates/${id}`);
};
