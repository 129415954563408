import {
  AlertTitle,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { forwardRef, useEffect, useState } from "react";

import { useToast } from "../../contexts/ToastContext";

const useStyles = makeStyles({
  alert: {
    "& .MuiAlert-message": {
      width: "100%",
    },
  },
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Toast = () => {
  const classes = useStyles();
  const { toast, closeToast } = useToast();
  const [progress, setProgress] = useState<number>(100);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (toast && toast.open && toast.autoHideDuration) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          return Math.max(
            oldProgress - (250 / toast.autoHideDuration) * 100,
            0,
          );
        });
      }, 250);
    }
    return () => {
      clearInterval(timer);
    };
  }, [toast?.open]);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (toast?.onClose) toast.onClose();
    closeToast();
    setProgress(100);
  };

  if (!toast) return <></>;

  return (
    <Snackbar
      open={toast.open}
      onClose={handleClose}
      onClick={handleClose}
      autoHideDuration={toast.autoHideDuration}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ minWidth: "240px", maxWidth: "300px", cursor: "pointer" }}
    >
      <Alert
        onClose={handleClose}
        severity={toast.severity}
        sx={{ width: "100%" }}
        className={classes.alert}
      >
        <AlertTitle>
          {toast.severity[0].toUpperCase() +
            toast.severity.slice(1, toast.severity.length)}
        </AlertTitle>
        <Typography component="div" variant="body2">
          {toast.message}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            mr: "-54px",
            ml: "-50px",
            mt: "6px",
            mb: "-14px",
            height: "5px",
          }}
        />
      </Alert>
    </Snackbar>
  );
};
