import { KeycloakProfile } from "keycloak-js";
import { createContext, FC, useContext, useState } from "react";

import { Profile } from "../types/profile";

export type AuthContextType = {
  profile: KeycloakProfile | null;
  setProfile: (profile: Profile | null) => void;
  isLoading: boolean;
  setIsLoading: (b: boolean) => void;
};

export const AuthContext = createContext<AuthContextType>({
  profile: null,
  setProfile: (_profile: KeycloakProfile | null) =>
    console.warn("No profile found"),
  isLoading: true,
  setIsLoading: (_b: boolean) => console.warn("No implemented"),
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ profile?: KeycloakProfile }> = (props) => {
  const [authState, setAuthState] = useState<KeycloakProfile | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const setProfile = (profile: KeycloakProfile | null) => {
    setAuthState(profile);
  };

  (window as any).setAuthState = setAuthState;
  (window as any).setIsLoading = setIsLoading;

  return (
    <AuthContext.Provider
      value={{
        profile: authState,
        setProfile,
        isLoading,
        setIsLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
