import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import theme from "../../common/theme";
import { Campaign } from "../../types/campaign";
import { CampaignHyperLink } from "../../types/campaign-hyperlink";
import {
  ISubscriberMostOpen,
  ITopLocationByOpen,
  ITrackingTimeSeriesData,
  OverviewStat,
  SendingStat,
} from "../../types/report";

import ClickMap from "./ClickMap";

am4core.useTheme(am4themes_animated);

interface IProps {
  overviewStat: OverviewStat;
  topLinksClicked: CampaignHyperLink[];
  sendingStat: SendingStat;
  campaign: Campaign;
  trackingTimeseriesData: ITrackingTimeSeriesData[] | null;
  topLocationByOpens: ITopLocationByOpen[];
  subscribersWithMostOpens: ISubscriberMostOpen[];
  changeTab: (index: number) => void;
}

const useStyles = makeStyles(() => ({
  dot: {
    display: "flex",
    alignItems: "center",
  },
  campaignPreview: {
    height: 360,
    width: 360,
    overflow: "hidden",
    borderStyle: "none!important",
  },
  statBlock: {
    paddingTop: 30,
    paddingBottom: 30,
    border: "1px solid #bdbbb9",
    borderLeft: "0 none",
    "&:first-child": {
      borderLeft: "1px solid #bdbbb9",
      borderRadius: "4px 0 0 4px",
    },
    "&:last-child": {
      borderRadius: "0 4px 4px 0",
    },
    flex: 1,
    textAlign: "center",
  },
  leaders: {
    overflowX: "hidden",
    listStyle: "none",
    "& li": {
      marginBottom: 12,
      background:
        "url(https://us17.admin.mailchimp.com/release/1.1.1e5086daf106041a0c7ebf9e6bc26a7fb4859687b/images/bg-leaders.png) transparent 0 17px repeat-x",
      "&>span+span": {
        float: "right",
        paddingLeft: 12,
      },
    },
  },
}));

const Overview: FC<IProps> = ({
  overviewStat,
  topLinksClicked,
  sendingStat,
  campaign,
  trackingTimeseriesData,
  subscribersWithMostOpens,
  topLocationByOpens,
  changeTab,
}: IProps) => {
  const classes = useStyles();
  const [isClickMapVisible, setIsClickMapVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!trackingTimeseriesData) {
      return;
    }
    const chart = am4core.create("chartdiv", am4charts.XYChart);

    chart.data = trackingTimeseriesData.map((item) => {
      return {
        time: new Date(item.time),
        clicks: item.clicks,
        opens: item.opens,
      };
    });
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.baseInterval = {
      timeUnit: "hour",
      count: 1,
    };

    chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "clicks";
    series.dataFields.dateX = "time";
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = "[bold]Clicks:[/] {clicks}\n[bold]Opens:[/] {opens}";
    if (series.tooltip) series.tooltip.pointerOrientation = "vertical";

    // Create series
    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "opens";
    series2.dataFields.dateX = "time";
    series2.strokeWidth = 2;
    series2.strokeDasharray = "3,4";
    series2.stroke = series.stroke;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [trackingTimeseriesData]);

  useEffect(() => {
    if (!topLocationByOpens.length) {
      return;
    }

    const chart = am4core.create("chartdiv-open-map", am4maps.MapChart);

    /* Set map definition */
    chart.geodata = am4geodata_worldLow;

    /* Set projection */
    chart.projection = new am4maps.projections.Miller();

    /* Create map polygon series */
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    const data = topLocationByOpens.map((item) => ({
      id: item.countryCode,
      value: item.opens,
    }));
    polygonSeries.data = data;

    //Set min/max fill color for each area
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(2),
      max: chart.colors.getIndex(1).brighten(-0.3),
    });

    /* Make map load polygon (like country names) data from GeoJSON */
    polygonSeries.useGeodata = true;

    /* Configure series */
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.applyOnClones = true;
    polygonTemplate.togglable = true;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeOpacity = 0.5;
    polygonTemplate.fill = chart.colors.getIndex(0);
    let lastSelected: am4maps.MapPolygon;
    polygonTemplate.events.on("hit", function (ev) {
      if (lastSelected) {
        // This line serves multiple purposes:
        // 1. Clicking a country twice actually de-activates, the line below
        //    de-activates it in advance, so the toggle then re-activates, making it
        //    appear as if it was never de-activated to begin with.
        // 2. Previously activated countries should be de-activated.
        lastSelected.isActive = false;
      }
      ev.target.series.chart.zoomToMapObject(ev.target);
      if (lastSelected !== ev.target) {
        lastSelected = ev.target;
      }
    });

    // Configure series tooltip
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    /* Create selected and hover states and set alternative fill color */
    const ss = polygonTemplate.states.create("active");
    ss.properties.fill = chart.colors.getIndex(2);

    const hs = polygonTemplate.states.create("hover");
    hs.properties.fill = chart.colors.getIndex(4);

    // Hide Antarctica
    polygonSeries.exclude = ["AQ"];

    // Small map
    chart.smallMap = new am4maps.SmallMap();
    // Re-position to top right (it defaults to bottom left)
    chart.smallMap.align = "right";
    chart.smallMap.valign = "top";
    chart.smallMap.series.push(polygonSeries);

    // Zoom control
    chart.zoomControl = new am4maps.ZoomControl();
    chart.zoomControl.slider.height = 100;

    const homeButton = new am4core.Button();
    homeButton.events.on("hit", function () {
      chart.goHome();
    });

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.marginBottom = 10;
    homeButton.parent = chart.zoomControl;
    homeButton.insertBefore(chart.zoomControl.plusButton);

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [topLocationByOpens]);

  const totalOpensByLocations = useMemo(
    () =>
      topLocationByOpens.reduce(
        (n: number, { opens }: { opens: number }) => n + opens,
        0,
      ),
    [topLocationByOpens],
  );

  const handleCloseClickMap = () => {
    setIsClickMapVisible(false);
  };

  const totalClicks = useMemo(
    () =>
      topLinksClicked
        ? topLinksClicked.reduce(
            (n: number, { clicks }: { clicks: number }) => n + clicks,
            0,
          )
        : 0,
    [topLinksClicked],
  );

  const totalUniqueClicks = useMemo(
    () =>
      topLinksClicked
        ? topLinksClicked.reduce(
            (n: number, { uniqueClicks }: { uniqueClicks: number }) =>
              n + uniqueClicks,
            0,
          )
        : 0,
    [topLinksClicked],
  );

  return (
    <>
      <Typography variant="h4" style={{ flex: 1, marginTop: theme.spacing(2) }}>
        Sent to: {overviewStat?.contactSentCount || 0} /{" "}
        {overviewStat.contactCount || 0} Recipients
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: theme.spacing(1) }}>
            <div>
              <strong>Audience:&ensp;</strong>
              {overviewStat.campaign.audienceName || ""}
            </div>
          </Box>
          <Box sx={{ padding: theme.spacing(1) }}>
            <div>
              <strong>Subject:&ensp;</strong>
              {overviewStat.campaign.subject || ""}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: theme.spacing(1) }}>
            <div>
              <strong>Delivered:&ensp;</strong>
              {overviewStat
                ? moment(overviewStat.campaign.deliveryDate).format(
                    "YYYY-MM-DD HH:mm:ss A",
                  )
                : ""}
            </div>
          </Box>
          <Stack direction="row" sx={{ padding: theme.spacing(1) }}>
            <Button
              variant="text"
              component={RouterLink}
              to={`/campaigns/show/${overviewStat?.campaign.id || ""}`}
            >
              View email
            </Button>
            <div className={classes.dot}> · </div>
            <Button variant="text">Download</Button>
            <div className={classes.dot}> · </div>
            <Button variant="text">Print</Button>
            <div className={classes.dot}> · </div>
            <Button variant="text">Share</Button>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          border: "1px solid rgba(36,28,21,.15)",
          borderRadius: "4px",
          mt: theme.spacing(3),
        }}
      >
        <Box sx={{ p: 3, borderBottom: "1px solid rgba(36,28,21,.15)" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ padding: theme.spacing(1) }}>
                <Typography variant="h4">
                  <MarkEmailReadIcon
                    style={{ color: "rgba(36,28,21,0.65)" }}
                    sx={{ mr: 2 }}
                  />
                  Click performance
                </Typography>
              </Box>
              <div style={{ color: "rgba(36,28,21,0.65)" }}>
                See how many times contacts clicked each of the links in your
                email.
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => setIsClickMapVisible(true)}
              >
                View Click Map
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Stack direction="row">
          <Box sx={{ p: 3, flexGrow: 1 }}>
            <Typography variant="h5">Top links clicked</Typography>
            <Stack direction="column" sx={{ py: theme.spacing(2) }}>
              {topLinksClicked &&
                topLinksClicked
                  .slice(0, 5)
                  .sort((a, b) => (a.clicks < b.clicks ? 1 : -1))
                  .map((link: CampaignHyperLink) => (
                    <Stack
                      key={link.id}
                      direction="row"
                      sx={{
                        py: theme.spacing(2),
                        borderBottom: "1px solid rgba(36,28,21,.15)",
                        justifyContent: "space-between",
                        flexWrap: "nowrap",
                      }}
                    >
                      <RouterLink to="/dashboard" className="link">
                        {link.href}
                      </RouterLink>
                      {link.clicks}
                    </Stack>
                  ))}
            </Stack>
            <Button variant="text" onClick={() => changeTab(1)}>
              View All Links
            </Button>
          </Box>
          <Box
            sx={{
              backgroundColor: "#f6f6f4",
              borderLeft: "1px solid rgba(36,28,21,.15)",
            }}
          >
            <Box sx={{ m: 3 }}>
              <iframe
                srcDoc={campaign.html}
                scrolling="no"
                className={classes.campaignPreview}
              ></iframe>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Stack direction="row" sx={{ mt: 5 }}>
        <Box className={classes.statBlock}>
          <Typography variant="h4">{sendingStat?.uniqueOpens || 0}</Typography>
          <div>Opened</div>
        </Box>
        <Box className={classes.statBlock}>
          <Typography
            variant="h4"
            className="link"
            onClick={() => changeTab(4)}
          >
            {sendingStat?.uniqueClicks || 0}
          </Typography>
          <div>Clicked</div>
        </Box>
        <Box className={classes.statBlock}>
          <Typography
            variant="h4"
            className="link"
            onClick={() => changeTab(3)}
          >
            {sendingStat?.bounces || 0}
          </Typography>
          <div>Bounced</div>
        </Box>
        <Box className={classes.statBlock}>
          <Typography variant="h4">N/A</Typography>
          <div>Unsubscribed</div>
        </Box>
      </Stack>
      <Grid container spacing={2} sx={{ mt: theme.spacing(1) }}>
        <Grid item xs={12} md={6}>
          <ul className={classes.leaders}>
            <li>
              <span>Successful deliveries</span>
              <span className="fwb">
                <b>{overviewStat?.contactSentCount || 0}</b>
                <span style={{ marginLeft: theme.spacing(2) }}>
                  {overviewStat
                    ? (
                        (overviewStat.contactSentCount /
                          overviewStat.contactCount) *
                        100
                      ).toFixed(1)
                    : 0}
                  %
                </span>
              </span>
            </li>
            <li>
              <span>Total opens</span>
              <span className="fwb">
                <a
                  href="/reports/activity/opened?id=13393332"
                  title="View"
                  className="link"
                >
                  <b>{sendingStat?.opens || 0}</b>
                </a>{" "}
              </span>
            </li>
            <li>
              <span>Unique opens</span>
              <span className="fwb">
                <span>{sendingStat?.uniqueOpens || 0}</span>
              </span>
            </li>
            <li>
              <span>Last opened</span>
              <span>
                {sendingStat?.lastOpened
                  ? moment(sendingStat.lastOpened).format(
                      `YYYY-MM-DD HH:mm:ss A`,
                    )
                  : "N/A"}
              </span>
            </li>
            <li>
              {" "}
              <span>Forwarded</span>{" "}
              <span className="fwb">
                <span>N/A</span>
              </span>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <ul className={classes.leaders}>
            <li>
              <span>Clicks per unique opens</span>
              <span className="fwb">
                <a
                  href="/reports/clicks?id=13393332"
                  title="View"
                  className="link"
                >
                  <b>
                    {sendingStat?.clicks
                      ? (
                          (sendingStat.uniqueClicks / sendingStat.clicks) *
                          100
                        ).toFixed(1)
                      : 0}
                    %
                  </b>
                </a>
              </span>
            </li>
            <li>
              <span>Total clicks</span>
              <span className="fwb">
                <a
                  href="/reports/clicks?id=13393332"
                  title="View"
                  className="link"
                >
                  <b>{sendingStat?.clicks || 0}</b>
                </a>
              </span>
            </li>
            <li>
              <span>Last clicked</span>
              <span>
                {sendingStat?.lastClicked
                  ? moment(sendingStat.lastClicked).format(
                      `YYYY-MM-DD HH:mm:ss A`,
                    )
                  : "N/A"}
              </span>
            </li>
            <li>
              <span>Abuse reports</span>
              <span className="fwb">
                <b>N/A</b>
              </span>
            </li>
            <li>
              <span>Forward opens</span>
              <span className="fwb">
                <span>N/A</span>
              </span>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Box sx={{ mt: theme.spacing(5) }}>
        <Typography variant="h4">24-hour performance</Typography>
        <div
          id="chartdiv"
          style={{ width: "100%", height: 300, marginTop: theme.spacing(3) }}
        ></div>
      </Box>
      <Box sx={{ mt: theme.spacing(5) }}>
        <Typography variant="h4">Subscribers with most opens</Typography>
        {subscribersWithMostOpens.length ? (
          <Box>
            <Stack direction="column" sx={{ py: theme.spacing(2) }}>
              {subscribersWithMostOpens.map(
                (subscriber: ISubscriberMostOpen, index: number) => (
                  <Stack
                    key={`sub-most-open-${subscriber.email}-${index}`}
                    direction="row"
                    sx={{
                      py: theme.spacing(2),
                      borderBottom: "1px solid rgba(36,28,21,.15)",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                    }}
                  >
                    <RouterLink to="/audiences/dashboard" className="link">
                      {subscriber.email}
                    </RouterLink>
                    {subscriber.opens}
                  </Stack>
                ),
              )}
            </Stack>
            <Button variant="text" onClick={() => changeTab(1)}>
              View more
            </Button>
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography>No data available</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: theme.spacing(5) }}>
        <Typography variant="h4">Top locations by opens</Typography>
        {topLocationByOpens.length ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Stack direction="column" sx={{ py: theme.spacing(2) }}>
                {topLocationByOpens.map((item: ITopLocationByOpen) => (
                  <Stack
                    key={`country-${item.countryCode}`}
                    direction="row"
                    sx={{
                      py: theme.spacing(2),
                      borderBottom: "1px dotted rgba(36,28,21,.15)",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                    }}
                  >
                    <div
                      className="float-left"
                      style={{
                        background: `url('/assets/countries/${item.countryCode}.png') no-repeat 0 center`,
                        paddingLeft: 45,
                      }}
                    >
                      {item.country}
                    </div>
                    <div>
                      {item.opens}&emsp;
                      <b>
                        {((item.opens / totalOpensByLocations) * 100).toFixed(
                          1,
                        )}
                        %
                      </b>
                    </div>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={7}>
              <div
                id="chartdiv-open-map"
                style={{
                  width: "100%",
                  height: 500,
                  marginTop: theme.spacing(3),
                }}
              ></div>
            </Grid>
          </Grid>
        ) : (
          <Box textAlign="center">
            <Typography>No data available</Typography>
          </Box>
        )}
      </Box>
      <ClickMap
        open={isClickMapVisible}
        campaign={campaign}
        links={topLinksClicked}
        totalClicks={totalClicks}
        totalUniqueClicks={totalUniqueClicks}
        handleClose={handleCloseClickMap}
      />
    </>
  );
};

export default Overview;
