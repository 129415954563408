import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Portal from "@mui/material/Portal";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { FC, forwardRef, Ref, useCallback, useState } from "react";

import theme from "../../common/theme";
import { Campaign } from "../../types/campaign";
import { CampaignHyperLink } from "../../types/campaign-hyperlink";

const useStyles = makeStyles(() => ({
  linkItem: {
    // border: "1px solid rgba(36,28,21,.15)"
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  campaign: Campaign;
  open: boolean;
  links: CampaignHyperLink[];
  totalClicks: number;
  totalUniqueClicks: number;
  handleClose: () => void;
}

interface IClickNode {
  node: HTMLAnchorElement;
  link: CampaignHyperLink;
  originalStyle: {
    borderStyle: string;
    borderColor: string;
    borderRadius: string;
    borderWidth: string;
  } | null;
}

const ClickMap: FC<IProps> = ({
  campaign,
  open,
  links,
  totalClicks,
  totalUniqueClicks,
  handleClose,
}: IProps) => {
  const classes = useStyles();
  const [hyperLinks, setHyperLinks] = useState<IClickNode[]>([]);
  const [hoveredLink, setHoveredLink] = useState<IClickNode | null>(null);
  const [selectedLink, setSelectedLink] = useState<CampaignHyperLink | null>(
    null,
  );

  const previewHTMLRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const arr: IClickNode[] = [];
        for (const n of node.getElementsByTagName("a")) {
          const l = links.find(
            (item) => n.getAttribute("data-link-id") === item.id,
          );
          if (n.hasAttribute("data-link-id") && l) {
            arr.push({
              node: n,
              link: l,
              originalStyle: null,
            });
          }
        }

        setHyperLinks(arr);
      }
    },
    [links],
  );

  const onMouseOver = (link: IClickNode) => {
    setHoveredLink(link);
    let node: HTMLElement;
    if (link.node.childElementCount) {
      node = link.node.children[0] as HTMLElement;
    } else {
      node = link.node;
    }
    if (!link.originalStyle) {
      link.originalStyle = {
        borderStyle: node.style.borderStyle,
        borderColor: node.style.borderColor,
        borderRadius: node.style.borderRadius,
        borderWidth: node.style.borderWidth,
      };
    }
    node.style.borderStyle = "solid";
    node.style.borderColor = theme.palette.primary.main;
    node.style.borderRadius = "4px";
    node.style.borderWidth = "2px";
  };

  const onMouseOut = () => {
    if (hoveredLink) {
      setHoveredLink(null);
      let node: HTMLElement;
      if (hoveredLink.node.childElementCount) {
        node = hoveredLink.node.children[0] as HTMLElement;
      } else {
        node = hoveredLink.node;
      }
      node.style.borderStyle = hoveredLink.originalStyle?.borderStyle || "";
      node.style.borderColor = hoveredLink.originalStyle?.borderColor || "";
      node.style.borderRadius = hoveredLink.originalStyle?.borderRadius || "";
      node.style.borderWidth = hoveredLink.originalStyle?.borderWidth || "";
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Click Map
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} height="100%" sx={{ overflowY: "scroll" }}>
        <Grid item xs={12} md={8} height="100%" sx={{ overflowY: "scroll" }}>
          <div
            ref={previewHTMLRef}
            dangerouslySetInnerHTML={{ __html: campaign.html }}
          ></div>
        </Grid>
        <Grid item xs={12} md={4}>
          {selectedLink ? (
            <>
              <Box position="relative">
                <IconButton
                  aria-label="delete"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={() => setSelectedLink(null)}
                  size="large"
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography
                  padding={theme.spacing(2)}
                  variant="h6"
                  component="div"
                  textAlign="center"
                >
                  Link Details
                </Typography>
              </Box>
              <Box
                fontSize={14}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <b>URL: </b>
                {selectedLink.href}
              </Box>
              <Box border="1px solid rgba(36,28,21,.15)" mt={theme.spacing(3)}>
                <Box padding={theme.spacing(3)}>
                  <Stack direction="row" alignItems="center">
                    <Box flex={1}>
                      <Box>
                        <b>Total Clicks</b>
                      </Box>
                      <Box color="#241c15a6">
                        {totalClicks
                          ? ((selectedLink.clicks / totalClicks) * 100).toFixed(
                              1,
                            )
                          : 0}
                        % of all clicks
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        padding={theme.spacing(2)}
                        variant="h4"
                        component="div"
                      >
                        {selectedLink.clicks}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box flex={1}>
                      <Box>
                        <b>Unique Clicks</b>
                      </Box>
                      <Box color="#241c15a6">
                        {totalUniqueClicks
                          ? (
                              (selectedLink.uniqueClicks / totalUniqueClicks) *
                              100
                            ).toFixed(1)
                          : 0}
                        % of all unique clicks
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        padding={theme.spacing(2)}
                        variant="h4"
                        component="div"
                      >
                        {selectedLink.uniqueClicks}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                {/* <Box borderTop="1px solid rgba(36,28,21,.15)" padding={theme.spacing(3)} textAlign="right">
                    See All Contacts Who Clicked
                  </Box> */}
              </Box>
            </>
          ) : (
            <>
              <Typography
                padding={theme.spacing(2)}
                variant="h6"
                component="div"
                textAlign="center"
              >
                Click Summary
              </Typography>

              {hyperLinks.map((item) => (
                <Box
                  onMouseOver={() => onMouseOver(item)}
                  onMouseOut={() => onMouseOut()}
                  onClick={() => setSelectedLink(item.link)}
                >
                  <Button
                    fullWidth
                    className={classes.linkItem}
                    sx={{ flexDirection: "column", alignItems: "flex-start" }}
                    variant={
                      hoveredLink?.link.id === item.link.id
                        ? "contained"
                        : "text"
                    }
                  >
                    <Stack
                      direction="row"
                      sx={{ padding: theme.spacing(1), width: "100%" }}
                    >
                      <Box
                        flex={1}
                        textAlign="left"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {item.link.href}
                      </Box>
                      <div>
                        {totalClicks
                          ? ((item.link.clicks / totalClicks) * 100).toFixed(1)
                          : 0}
                        %
                      </div>
                      <div>
                        <KeyboardArrowRightIcon />
                      </div>
                    </Stack>
                    <LinearProgress
                      variant="determinate"
                      value={
                        totalClicks ? (item.link.clicks / totalClicks) * 100 : 0
                      }
                      sx={{ width: "100%" }}
                      color="warning"
                    />
                  </Button>
                </Box>
              ))}
            </>
          )}
        </Grid>
      </Grid>
      {hyperLinks.length &&
        hyperLinks.map((item) => {
          return (
            <Portal key={item.link.id} container={item.node}>
              <Popper
                open={true}
                anchorEl={item.node}
                style={{
                  zIndex: item.link.id === hoveredLink?.link.id ? 10000 : 9999,
                }}
                placement="top-end"
              >
                <Paper
                  elevation={10}
                  sx={{
                    cursor: "pointer",
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor:
                      item.link.id === hoveredLink?.link.id
                        ? theme.palette.primary.main
                        : "white",
                    backgroundColor:
                      item.link.id === hoveredLink?.link.id
                        ? "#e7e7e7"
                        : "white",
                  }}
                  onMouseOver={() => onMouseOver(item)}
                  onMouseOut={() => onMouseOut()}
                  onClick={() => setSelectedLink(item.link)}
                >
                  <Typography sx={{ p: 1 }}>
                    <b>{item.link.clicks}</b>&ensp;(
                    {totalClicks
                      ? ((item.link.clicks / totalClicks) * 100).toFixed(1)
                      : 0}
                    %)
                  </Typography>
                </Paper>
              </Popper>
            </Portal>
          );
        })}
    </Dialog>
  );
};

export default ClickMap;
