import Keycloak from "keycloak-js";
import Cookies from "universal-cookie";

const initOptions = {
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  url: process.env.REACT_APP_KEYCLOAK_URL || "",
};

const cookies = new Cookies();

export const keycloak = new Keycloak(initOptions);

export const initKeycloak = () => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      flow: "implicit",
    })
    .then((auth) => {
      if (auth) {
        keycloak.loadUserProfile().then((p) => {
          console.error(p);
          if (p.email?.endsWith("steamforvietnam.org")) {
            (window as any).setAuthState(p);
          } else {
            console.error(111);
            (window as any).openToast("error", "Only STEAM member can login");
          }
        });

        const date = new Date();
        const expireMs = 30 * 365 * 24 * 60 * 60 * 1000; // 100 days
        date.setTime(date.getTime() + expireMs);

        cookies.set("steamid-jwt", keycloak.token, {
          expires: date,
          path: "/",
          sameSite: "strict",
        });
      } else {
        cookies.remove("steamid-jwt");
      }
    })
    .catch((e) => console.error(e))
    .finally(() => (window as any).setIsLoading(false));
};
