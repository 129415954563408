import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import clsx from "clsx";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CLOSE_DRAWER_WIDTH, OPEN_DRAWER_WIDTH } from "../../common/constants";
import theme from "../../common/theme";
import { BaseRoute, routes } from "../../constants/routes";
import { useAuth } from "../../contexts/AuthContext";
import { saveCampaign } from "../../http/campaign";
import { TextAvatar } from "../TextAvatar/TextAvatar";

const useStyles = makeStyles({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: OPEN_DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
  },
  drawerOpen: {
    width: OPEN_DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: CLOSE_DRAWER_WIDTH,
    [theme.breakpoints.up("sm")]: {
      width: CLOSE_DRAWER_WIDTH,
    },
    overflowX: "hidden",
  } as const,
  drawerBaseRoute: {
    padding: theme.spacing(2, 0, 2, 3),
  },
  drawerSubRoute: {
    padding: theme.spacing(1, 1),
  },
});

const BlackRightTooltip = styled(
  ({
    className,
    marginLeft,
    ...props
  }: TooltipProps & { marginLeft?: number }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      PopperProps={{ keepMounted: true }}
    />
  ),
)(({ marginLeft }: { marginLeft?: number }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.black.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.black.main,
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: `${marginLeft || 0}px !important`,
  },
}));

const DesktopDrawer = ({
  onCloseDrawer,
  onOpenDrawer,
  isOpen,
  onToggleDrawer,
  onLogout,
}: {
  onCloseDrawer: () => void;
  onOpenDrawer: () => void;
  isOpen: boolean;
  onToggleDrawer: () => void;
  onLogout: () => void;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useAuth();

  const indexOfMatchRoute = (array: BaseRoute[]): number => {
    const match = array.filter(
      (route) => location.pathname.indexOf(route.to) >= 0,
    );
    return match.length ? array.indexOf(match[0]) : -1;
  };

  const currentRoute = indexOfMatchRoute(routes.map((route) => route.base));
  const [selectedRoute, setSelectedRoute] = useState<number>(0);
  const [selectedSubRoute, setSelectedSubRoute] = useState<number>(0);

  const [accountAnchorEl, setAccountAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const openAccountMenu = Boolean(accountAnchorEl);

  useEffect(() => {
    const parent = indexOfMatchRoute(routes.map((route) => route.base));
    setSelectedRoute(parent);

    if (parent === -1) setSelectedSubRoute(-1);
    else setSelectedSubRoute(indexOfMatchRoute(routes[parent].sub));
  }, []);

  const handleClickAccountMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAccountAnchorEl(null);
  };

  const onClickListRoute = (
    _event: MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (index !== selectedRoute) {
      setSelectedRoute(index);
      if (!isOpen) onOpenDrawer();
    } else onToggleDrawer();
  };

  const onClickListSubRoute = (
    _event: MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (index !== selectedRoute) {
      setSelectedSubRoute(index);
    }
    const destination = routes[selectedRoute].sub[index].to;
    if (destination === "/campaigns/create") {
      createCampaign();
    } else navigate(destination);
  };

  const createCampaign = async () => {
    try {
      const response = await saveCampaign({
        name: "My Campaign",
      });
      navigate(`/campaigns/edit/${response.data.id}`);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Drawer
      sx={{ display: { xxs: "none", xs: "none", sm: "block" } }}
      variant="permanent"
      PaperProps={{ elevation: 3 }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <Stack
        sx={{ height: "100%" }}
        direction="row"
        divider={<Divider orientation="vertical" />}
      >
        {/* Mini drawer */}
        <Stack>
          <Box
            component="img"
            sx={{ width: 72, height: 72, my: 2, mx: 0.5 }}
            alt="STEAMify"
            src="https://steamese-profile.sgp1.digitaloceanspaces.com/assets/images/logo/logo-only@512x512.png"
          />
          <List sx={{ mt: 5 }}>
            {routes.map((route, index) => (
              <BlackRightTooltip
                title={
                  <Typography sx={{ py: 1 }} variant="body2">
                    {route.base.name}
                  </Typography>
                }
                placement="right"
                key={index}
              >
                <ListItemButton
                  className={classes.drawerBaseRoute}
                  selected={selectedRoute === index}
                  onClick={(event) => onClickListRoute(event, index)}
                >
                  <ListItemIcon>
                    {selectedRoute === index
                      ? route.base.iconSelected
                      : route.base.iconUnselected}
                  </ListItemIcon>
                </ListItemButton>
              </BlackRightTooltip>
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <List>
            <BlackRightTooltip
              title={
                <Typography sx={{ py: 1 }} variant="body2">
                  Account
                </Typography>
              }
              placement="right"
            >
              <ListItemButton
                component="button"
                className={classes.drawerBaseRoute}
                aria-haspopup="true"
                onClick={handleClickAccountMenu}
              >
                <ListItemIcon>
                  <TextAvatar
                    displayedName={profile?.firstName || ""}
                    sx={{ width: 32, height: 32 }}
                  />
                </ListItemIcon>
              </ListItemButton>
            </BlackRightTooltip>
            <Menu
              anchorEl={accountAnchorEl}
              open={openAccountMenu}
              onClose={handleCloseAccountMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem disabled>
                {profile?.firstName + " " + profile?.lastName}
              </MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </List>
        </Stack>
        <Stack sx={{ flexGrow: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ pb: 3 }}>
            <Typography variant="h6" sx={{ py: 1, pl: 1 }}>
              {routes[selectedRoute].base.name}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <BlackRightTooltip
              marginLeft={8}
              title={
                <Typography sx={{ py: 1 }} variant="body2">
                  Collapse
                </Typography>
              }
              placement="right"
            >
              <IconButton onClick={onCloseDrawer} sx={{ mr: 1 }} size="large">
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </BlackRightTooltip>
          </Stack>
          {/* Sub routes */}
          <List disablePadding>
            {routes[selectedRoute].sub.map((subRoute, subIndex) => (
              <Fragment key={subRoute.name}>
                {subIndex === routes[selectedRoute].sub.length - 1 &&
                  subIndex !== 0 && <Divider sx={{ my: 1 }} />}
                <ListItemButton
                  className={classes.drawerSubRoute}
                  selected={
                    selectedSubRoute === subIndex &&
                    selectedRoute === currentRoute
                  }
                  onClick={(event) => onClickListSubRoute(event, subIndex)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight:
                            selectedSubRoute === subIndex &&
                            selectedRoute === currentRoute
                              ? 500
                              : 400,
                        }}
                      >
                        {subRoute.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </Fragment>
            ))}
          </List>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default DesktopDrawer;
