import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  alpha,
  Box,
  Button,
  Link,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const CustomTableToolbar = ({
  numSelected,
  openDeletionDialog,
}: {
  numSelected: number;
  openDeletionDialog: () => void;
}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Tooltip title="Delete" arrow>
            <Button
              variant="contained"
              endIcon={<DeleteIcon />}
              onClick={openDeletionDialog}
            >
              Delete
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
          <Box sx={{ flex: "1 1 100%" }} />
          <Link
            component={RouterLink}
            to="/audiences/create"
            underline="none"
            style={{ color: "white" }}
          >
            <Button
              variant="contained"
              endIcon={<AddCircleIcon />}
              sx={{
                textTransform: "none",
                minWidth: "max-content",
                whiteSpace: "nowrap",
              }}
            >
              Create Audience
            </Button>
          </Link>
        </>
      )}
    </Toolbar>
  );
};
