/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckIcon from "@mui/icons-material/Check";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import debounce from "debounce-promise";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import EmailEditor, { HtmlExport } from "react-email-editor";
import { useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { MERGE_TAGS } from "../common/constants";
import theme from "../common/theme";
import PageLayout from "../components/Layout/PageLayout";
import { listImages } from "../http/image";
import { getTemplateById, updateTemplate } from "../http/template";
import { PaginationMeta } from "../types/http";
import { Image } from "../types/image";
import { Template } from "../types/template";

const useStyles = makeStyles(() => ({
  dragDrop: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#2196f3",
    },
  },
  imageItem: {
    border: "solid 2px transparent",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
    borderRadius: 4,
    "&:hover": {
      borderColor: "#2196f3",
    },
    "&>img": {
      borderRadius: 4,
    },
  },
}));

export default function TemplateCreator() {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const emailEditorRef = useRef<EmailEditor>(null);
  const [template, setTemplate] = useState<Template | undefined>(undefined);
  const [newName, setNewName] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isImageGalleryVisible, setIsImageGalleryVisible] =
    useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [pageMeta, setPageMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });
  const [images, setImages] = useState<Image[] | null>(null);
  const editorSelectImageCallbackRef = useRef<any>();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
  });

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      const response = await getTemplateById(parseInt(id));
      setTemplate(response.data);
      setNewName(response.data.name);
    })();
  }, []);

  useEffect(() => {
    if (isImageGalleryVisible && !images) {
      loadImages(pageMeta.currentPage, pageMeta.itemsPerPage);
    }
  }, [isImageGalleryVisible]);

  useEffect(() => {
    if (acceptedFiles.length) {
      (async () => {
        setIsUploading(true);
        try {
          const formData = new FormData();
          for (const file of acceptedFiles) {
            formData.append("files", file);
          }
          const response = await axios.post<Image[]>(
            `/api/templates/upload-images`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          setImages([...response.data, ...(images || [])]);
        } catch (error: any) {
          console.error(error);
        } finally {
          setIsUploading(false);
        }
      })();
    }
  }, [acceptedFiles]);

  const onLoad = useCallback(() => {
    const timer = setInterval(function () {
      if (template && emailEditorRef.current) {
        emailEditorRef.current.loadDesign(template.design);
        emailEditorRef.current.addEventListener("design:updated", () => {
          saveDebounce(false);
        });

        (emailEditorRef.current as any).registerCallback(
          "selectImage",
          function (_data: any, done: any) {
            setIsImageGalleryVisible(true);
            editorSelectImageCallbackRef.current = done;
          },
        );

        clearInterval(timer);
      }
    }, 500);
  }, [emailEditorRef, template]);

  const save = async (exit = true) => {
    if (!emailEditorRef.current || !template || !id) {
      return;
    }
    setIsSaving(true);

    try {
      const htmlData = await new Promise<HtmlExport>((resolve, _reject) => {
        (emailEditorRef.current as any).exportHtml(async (data: HtmlExport) => {
          resolve(data);
        });
      });

      const plainTextData = await new Promise<any>((resolve, _reject) => {
        (emailEditorRef.current as any).editor.exportPlainText((data: any) => {
          resolve(data);
        });
      });

      await updateTemplate(parseInt(id), {
        name: template.name,
        design: htmlData.design,
        html: htmlData.html,
        plainText: plainTextData.text,
      });

      if (exit) {
        navigate("/templates/list");
      } else {
        setIsSaving(false);
      }
    } catch (error: any) {
      console.error(error);
      setIsSaving(false);
    }
  };

  const saveDebounce = debounce(save, 3000);

  const updateTemplateName = async () => {
    if (!id) {
      setIsEdit(false);
      return;
    }

    try {
      await updateTemplate(parseInt(id), {
        name: newName,
      });

      template &&
        setTemplate({
          ...template,
          name: newName,
        });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsEdit(false);
    }
  };

  const loadImages = async (page = 1, limit = 10) => {
    try {
      const response = await listImages(page, limit);
      setPageMeta(response.data.meta);
      setImages(response.data.items);
    } catch (error: any) {
      console.error(error);
    }
  };

  const handlePaginationChange = (_: ChangeEvent<unknown>, page: number) => {
    loadImages(page);
  };

  const onSelectImage = (image: Image) => {
    editorSelectImageCallbackRef.current({ url: image.url });
    setIsImageGalleryVisible(false);
  };

  if (template === undefined) {
    return <PageLayout></PageLayout>;
  }

  return (
    <PageLayout>
      {template ? (
        <>
          <Stack direction="row" spacing={2} marginBottom={2}>
            <Box sx={{ flex: 1 }}>
              {isEdit ? (
                <Stack direction="column" spacing={2}>
                  <TextField
                    label="Name..."
                    variant="outlined"
                    style={{ width: 500 }}
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={updateTemplateName}>
                      Save
                    </Button>
                    <Button variant="text" onClick={() => setIsEdit(false)}>
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Box>
                  <Typography variant="h4" style={{ flex: 1 }}>
                    {template.name}
                  </Typography>
                  <Button variant="text" onClick={() => setIsEdit(true)}>
                    Edit name
                  </Button>
                </Box>
              )}
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              {isSaving ? (
                <Stack direction="row" spacing={2} alignItems="center">
                  <CircularProgress size={30} />
                  <Typography>Saving...</Typography>
                </Stack>
              ) : (
                <>
                  <CheckIcon color="success" />
                  <Typography>Saved</Typography>
                </>
              )}
              <Button
                variant="contained"
                onClick={() => save()}
                sx={{ height: 50 }}
              >
                Save and Exit
              </Button>
            </Stack>
          </Stack>
          <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            style={{ height: "75vh" }}
            options={{ mergeTags: MERGE_TAGS }}
          />
          <Dialog
            open={isImageGalleryVisible}
            onClose={() => setIsImageGalleryVisible(false)}
          >
            <DialogTitle>Image gallery</DialogTitle>
            <DialogContent sx={{ minHeight: 300, minWidth: 300 }}>
              <div className={classes.dragDrop} {...getRootProps()}>
                {isUploading ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <CircularProgress size={32} />
                    <div>Uploading...</div>
                  </Box>
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <p>Drag n drop some files here, or click to select files</p>
                  </>
                )}
              </div>
              <Pagination
                count={pageMeta.totalPages}
                onChange={handlePaginationChange}
                sx={{ mt: theme.spacing(3) }}
              />
              <ImageList variant="woven" cols={3} gap={8}>
                {(images || []).map((item) => (
                  <ImageListItem
                    key={item.id}
                    className={classes.imageItem}
                    onClick={() => onSelectImage(item)}
                  >
                    <img
                      src={`${item.url}`}
                      srcSet={`${item.url}`}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <Pagination
                count={pageMeta.totalPages}
                onChange={handlePaginationChange}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>No Template Found!</strong>
          </Alert>
          <Button
            variant="contained"
            fullWidth
            sx={{ marginTop: theme.spacing(2) }}
            component={RouterLink}
            to="/templates/list"
          >
            Back to homepage
          </Button>
        </>
      )}
    </PageLayout>
  );
}
