import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import theme from "../common/theme";
import { CustomTabPanel } from "../components/CustomTabPanel/CustomTabPanel";
import PageLayout from "../components/Layout/PageLayout";
import Bounced from "../components/Reports/Bounced";
import CampaignSent from "../components/Reports/CampaignSent";
import Clicked from "../components/Reports/Clicked";
import ClickPerformance from "../components/Reports/ClickPerformance";
import Opened from "../components/Reports/Open";
import Overview from "../components/Reports/Overview";
import { getCampaignById } from "../http/campaign";
import {
  getCampaignOverviewStat,
  getCampaignSendingStat,
  getClickReport,
  getSubscribersWithMostOpens,
  getTopLocationsByOpens,
  getTrackingTimeseriesData,
} from "../http/report";
import { Campaign } from "../types/campaign";
import { CampaignHyperLink } from "../types/campaign-hyperlink";
import {
  ISubscriberMostOpen,
  ITopLocationByOpen,
  ITrackingTimeSeriesData,
  OverviewStat,
  SendingStat,
} from "../types/report";

export default function Report() {
  const [value, setValue] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [overviewStat, setOverviewStat] = useState<OverviewStat | null>(null);
  const [links, setLinks] = useState<CampaignHyperLink[]>([]);
  const [sendingStat, setSendingStat] = useState<SendingStat | null>(null);
  const [subscribersWithMostOpens, setSubscribersWithMostOpens] = useState<
    ISubscriberMostOpen[]
  >([]);
  const [topLocationByOpens, setTopLocationByOpens] = useState<
    ITopLocationByOpen[]
  >([]);
  const [trackingTimeseriesData, setTrackingTimeseriesData] = useState<
    ITrackingTimeSeriesData[] | null
  >(null);

  useEffect(() => {
    _getCampaign();
  }, []);

  useEffect(() => {
    if (campaign && value === 0 && !overviewStat) {
      _getCampaignOverviewStat();
      _getClickPerformance();
      _getCampaignSendingStat();
      _getTrackingTimeseriesData();
      _getSubscribersWithMostOpens();
      _getTopLocationsByOpens();
    }
  }, [campaign, value]);

  const _getCampaign = async () => {
    try {
      if (!id) {
        return;
      }

      const response = await getCampaignById(parseInt(id));
      setCampaign(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _getCampaignOverviewStat = async () => {
    try {
      if (!id) {
        return;
      }

      const response = await getCampaignOverviewStat(parseInt(id));
      setOverviewStat(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _getClickPerformance = async () => {
    try {
      if (!campaign || !campaign.id) {
        return;
      }

      const response = await getClickReport(campaign.id);
      setLinks(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _getCampaignSendingStat = async () => {
    try {
      if (!id) {
        return;
      }

      const response = await getCampaignSendingStat(parseInt(id));
      setSendingStat(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _getTrackingTimeseriesData = async () => {
    try {
      if (!id) {
        return;
      }

      const response = await getTrackingTimeseriesData(parseInt(id));
      setTrackingTimeseriesData(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _getSubscribersWithMostOpens = async () => {
    try {
      if (!id) {
        return;
      }

      const response = await getSubscribersWithMostOpens(parseInt(id));
      setSubscribersWithMostOpens(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const _getTopLocationsByOpens = async () => {
    try {
      if (!id) {
        return;
      }

      const response = await getTopLocationsByOpens(parseInt(id));
      setTopLocationByOpens(response.data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const changeTab = (index: number) => {
    setValue(index);
    window.scrollTo(0, 0);
  };

  if (!campaign || !overviewStat || !sendingStat) {
    return (
      <PageLayout>
        <CircularProgress
          size={50}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-25px",
            marginLeft: "-25px",
          }}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Typography variant="h4" style={{ flex: 1 }}>
        {campaign.name}
      </Typography>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: theme.spacing(3),
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Overview" />
          <Tab label="Click Performance" />
          <Tab label="Campaign Sent" />
          <Tab label="Bounced" />
          <Tab label="Clicked" />
          <Tab label="Opened" />
          <Tab label="Didn't open" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Overview
          campaign={campaign}
          overviewStat={overviewStat}
          topLinksClicked={links}
          sendingStat={sendingStat}
          trackingTimeseriesData={trackingTimeseriesData}
          subscribersWithMostOpens={subscribersWithMostOpens}
          topLocationByOpens={topLocationByOpens}
          changeTab={changeTab}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ClickPerformance campaign={campaign} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <CampaignSent campaign={campaign} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Bounced campaign={campaign} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Clicked campaign={campaign} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Opened opened={true} campaign={campaign} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Opened opened={false} campaign={campaign} />
      </CustomTabPanel>
    </PageLayout>
  );
}
