import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Button,
  Container,
  Divider,
  FormControl,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { ChangeEvent, Fragment, MouseEvent, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { useToast } from "../../contexts/ToastContext";
import { listAudiences, listCampaignsByAudience } from "../../http/audience";
import { Audience } from "../../types/audience";
import { Campaign } from "../../types/campaign";
import { PaginationMeta } from "../../types/http";
import { CustomTable } from "../CustomTable";
import PageLayout from "../Layout/PageLayout";

export const ReportList = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const audienceId = query.get("audienceId");

  const { openToast } = useToast();

  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [pageMeta, setPageMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
  });
  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [currentAudience, setCurrentAudience] = useState<Audience | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChangeCurrentAudience = (event: SelectChangeEvent) => {
    const newAudience = audiences.find(
      (audience) => audience.name === event.target.value,
    );
    if (newAudience) {
      navigate(`/campaigns/reports?audienceId=${newAudience.id}`);
      setCurrentAudience(currentAudience);
    }
  };

  useEffect(() => {
    let active = true;
    setIsLoading(true);
    (async () => {
      try {
        let list: Audience[] = [];
        if (audiences.length === 0) {
          const response = await listAudiences(1, 50);
          list = response.data.items;
        } else {
          list = audiences;
        }

        if (!list) return;
        if (active) {
          setAudiences(list);
          if (!list.length) {
            setCurrentAudience(null);
            return;
          }
        }

        let found;
        if (!audienceId) {
          found = list[0];
          navigate(`/campaigns/reports?audienceId=${found.id}`);
        } else {
          found = list.find((obj) => obj.id === parseInt(audienceId));
        }
        if (active) {
          setCurrentAudience(found || null);
        }
      } catch (error: any) {
        setCurrentAudience(null);
        openToast("error", error.response?.data.message || error.message);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [audienceId]);

  useEffect(() => {
    let active = true;
    if (!audienceId) return;
    (async () => {
      try {
        const response = await listCampaignsByAudience(
          parseInt(audienceId),
          pageMeta.currentPage,
          pageMeta.itemsPerPage,
        );
        if (active) {
          setPageMeta(response.data.meta);
          setCampaigns(response.data.items);
        }
      } catch (error: any) {
        openToast("error", error.response?.data.message || error.message);
      }
    })();
    return () => {
      active = false;
    };
  }, [audienceId, pageMeta.currentPage, pageMeta.itemsPerPage]);

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPageMeta({ ...pageMeta, currentPage: page + 1 });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageMeta({
      ...pageMeta,
      currentPage: 1,
      itemsPerPage: parseInt(event.target.value, 10),
    });
  };

  return (
    <PageLayout>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ pb: 3 }}>
          Reports
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 3 }} />
        <Typography variant="h5" sx={{ pb: 3 }}>
          Current audience
        </Typography>
        <FormControl fullWidth>
          <Select
            value={currentAudience?.name || ""}
            defaultValue={currentAudience?.name || ""}
            onChange={onChangeCurrentAudience}
          >
            {audiences.map((audience, index) => (
              <MenuItem key={index} value={audience.name}>
                <Typography variant="body1">{audience.name}</Typography>
              </MenuItem>
            ))}
          </Select>
          {currentAudience ? (
            <>
              <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
                This audience has {currentAudience.numberOfContacts} contact
                {currentAudience.numberOfContacts < 2 ? "" : "s"}.
              </Typography>
            </>
          ) : !isLoading && audienceId ? (
            <>
              <Typography variant="body1" color="error" sx={{ pt: 3, pb: 3 }}>
                Audience not found or invalid.
              </Typography>
              <Typography variant="body1" sx={{ pb: 3 }}>
                Select an audience from menu above to see its reports.
              </Typography>
            </>
          ) : (
            <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
              Select an audience to see its reports.
            </Typography>
          )}
        </FormControl>
        <CustomTable
          tableHeads={
            <TableRow>
              <TableCell align="left">Report of campaign</TableCell>
              <TableCell align="left">Sent at</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          }
          tableBody={
            <>
              {campaigns.map((row, index) => (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <Typography>
                        <Link
                          component={RouterLink}
                          to={`/campaigns/reports/${row.id}`}
                          underline="none"
                        >
                          {row.name}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row.deliveryDate ? (
                          <>
                            <strong>Sent at</strong>{" "}
                            {moment(row.deliveryDate).format(
                              "YYYY-MM-DD HH:mm:ss A",
                            )}
                          </>
                        ) : (
                          "Not yet"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/campaigns/reports/${row.id}`}
                        underline="none"
                      >
                        <Button
                          endIcon={<AssignmentIcon />}
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            minWidth: "max-content",
                            whiteSpace: "nowrap",
                          }}
                        >
                          View report
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </>
          }
          pageMeta={pageMeta}
          amountColumns={3}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </PageLayout>
  );
};
