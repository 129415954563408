import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { ColDef } from "ag-grid-community";
import { FC, MouseEvent, useState } from "react";

import theme from "../../common/theme";
import { Campaign } from "../../types/campaign";
import { JobStatus } from "../../types/job";
import GridComponent from "../GridComponent";

interface IProps {
  campaign: Campaign;
}

const Bounced: FC<IProps> = ({ campaign }: IProps) => {
  const columnDefs: ColDef[] = campaign.audienceHeaders
    ? campaign.audienceHeaders.map((item) => {
        return {
          field: `data.${item.header}`,
          pinned: item.header === "email" ? "left" : undefined,
          headerName: item.header,
        };
      })
    : [];
  columnDefs.push({
    field: "errorCode",
    pinned: "left",
    headerName: "Reason",
  });
  const [status, setStatus] = useState(JobStatus.HARD_BOUNCED);

  const handleChange = (
    _event: MouseEvent<HTMLElement>,
    newAlignment: JobStatus,
  ) => {
    setStatus(newAlignment);
  };
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" flex={1} mt={theme.spacing(2)}>
          Bounced
        </Typography>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={status}
            exclusive
            onChange={handleChange}
            size="small"
          >
            <ToggleButton value={JobStatus.HARD_BOUNCED}>
              Hard bounced
            </ToggleButton>
            <ToggleButton value={JobStatus.SOFT_BOUNCED}>
              Soft bounced
            </ToggleButton>
            <ToggleButton value={JobStatus.FAILED}>Failed to Send</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>
      <Box
        className="ag-theme-alpine"
        width={"100%"}
        height={500}
        mt={theme.spacing(3)}
      >
        <GridComponent
          columnDefs={columnDefs}
          rowModelType={"serverSide"}
          httpEndpoint={`/api/reports/${campaign.id}/campaign-sent?status=${status}&`}
          pagination={true}
        />
      </Box>
    </>
  );
};

export default Bounced;
