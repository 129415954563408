import { Navigate, Route, Routes } from "react-router-dom";

import { AudienceDetail } from "./components/AudienceDetail/AudienceDetail";
import { AudienceImport } from "./components/AudienceImport/AudienceImport";
import { AudienceCreator } from "./components/AudiencesList/AudienceCreator";
import { ContactDetail } from "./components/ContactDetail/ContactDetail";
import { ReportList } from "./components/ReportList/ReportList";
import { useAuth } from "./contexts/AuthContext";
import AudiencesPage from "./pages/AudiencesPage";
import CampaignCreator from "./pages/CampaignCreator";
import CampaignDetail from "./pages/CampaignDetail";
import CampaignList from "./pages/CampaignList";
import DashboardPage from "./pages/DashboardPage";
import Report from "./pages/Report";
import Search from "./pages/Search";
import SignIn from "./pages/SignIn";
// import SignUp from "./pages/SignUp";
import TemplateCreator from "./pages/TemplateCreator";
import TemplateList from "./pages/TemplateList";
import TemplateSelector from "./pages/TemplateSelector";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

function App() {
  const { profile } = useAuth();

  return (
    <div className="App">
      <>
        {profile ? (
          <Routes>
            <Route path="/audiences/dashboard" element={<DashboardPage />} />
            <Route path="/audiences/list" element={<AudiencesPage />} />
            <Route path="/audiences/:id/import" element={<AudienceImport />} />
            <Route
              path="/audiences/:audienceId/contacts/:contactEmail"
              element={<ContactDetail />}
            />
            <Route path="/audiences/create" element={<AudienceCreator />} />
            <Route path="/audiences/view/:id" element={<AudienceDetail />} />
            <Route path="/audiences/view" element={<AudienceDetail />} />
            <Route path="/campaigns/reports/:id" element={<Report />} />
            <Route path="/campaigns/reports" element={<ReportList />} />
            <Route path="/campaigns/list" element={<CampaignList />} />
            <Route path="/campaigns/edit/:id" element={<CampaignCreator />} />
            <Route path="/campaigns/show/:id" element={<CampaignDetail />} />
            <Route path="/templates/list" element={<TemplateList />} />
            <Route path="/templates/edit/:id" element={<TemplateCreator />} />
            <Route path="/templates/create" element={<TemplateSelector />} />
            <Route path="/search" element={<Search />} />
            <Route
              path="*"
              element={<Navigate to="/audiences/dashboard" replace />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<SignIn />} />
            {/* <Route  path="/register" element={SignUp} /> */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </>
    </div>
  );
}

export default App;
