import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { Audience } from "../../types/audience";

export const CompleteImport = ({ audience }: { audience: Audience | null }) => {
  return (
    <>
      <Box sx={{ minHeight: "300px" }}>
        <>
          <Typography variant="h5" sx={{ pb: 3, pt: 1 }}>
            Contacts successfully imported
          </Typography>
        </>
      </Box>
      <Stack direction="row" justifyContent="center" spacing={3} sx={{ pt: 2 }}>
        <Link
          component={RouterLink}
          to={`/campaigns/list`}
          underline="none"
          color="inherit"
        >
          <Button
            size="large"
            variant="contained"
            color="secondary"
            sx={{
              textTransform: "none",
              minWidth: "max-content",
              whiteSpace: "nowrap",
            }}
          >
            Start a campaign
          </Button>
        </Link>
        <Link
          component={RouterLink}
          to={`/audiences/view/${audience?.id}`}
          underline="none"
          color="inherit"
        >
          <Button
            size="large"
            variant="contained"
            sx={{
              textTransform: "none",
              minWidth: "max-content",
              whiteSpace: "nowrap",
            }}
          >
            View your audience
          </Button>
        </Link>
      </Stack>
    </>
  );
};
