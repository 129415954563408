import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import theme from "../../common/theme";
import { useToast } from "../../contexts/ToastContext";
import {
  getAudienceHeaders,
  importContactsFromBigQuery,
  listAudiences,
} from "../../http/audience";
import { Audience, AudienceHeader } from "../../types/audience";
import { RefreshBQDialog } from "../Dialog/RefreshBQDialog";
import GridComponent from "../GridComponent";
import PageLayout from "../Layout/PageLayout";

import { CustomTableToolbar } from "./CustomTableToolbar";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(4, 0),
  },
});

export const AudienceDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { openToast } = useToast();

  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [currentAudience, setCurrentAudience] = useState<Audience | null>(null);
  const [currentAudienceHeaders, setCurrentAudienceHeaders] = useState<
    AudienceHeader[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openRefreshBq, setOpenRefreshBq] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact | null>(null);

  const columnDefs = currentAudienceHeaders.map((item) => {
    return {
      id: item.id,
      field: item.header,
      headerName: item.header,
      checkboxSelection: item.header === "email",
      pinned: item.header === "email" ? "left" : undefined,
      cellRenderer: item.header === "email" ? "contactLink" : undefined,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contactLink = (params: any) => {
    const email = params.data.email;
    return (
      <Link
        component={RouterLink}
        to={`/audiences/${currentAudience?.id}/contacts/${email}`}
        underline="none"
      >
        <Typography sx={{ fontWeight: "medium" }}>{email}</Typography>
      </Link>
    );
  };

  const onChangeCurrentAudience = (event: SelectChangeEvent) => {
    const newAudience = audiences.find(
      (audience) => audience.name === event.target.value,
    );
    if (newAudience) {
      navigate(`/audiences/view/${newAudience.id}`);
      setCurrentAudience(currentAudience);
    }
  };

  const onSelection = (event: SelectionChangedEvent) => {
    // each row is contact.data
    // so email is the only field that every contact has
    setSelectedRows(event.api.getSelectedRows().map((row) => row.email));
  };

  useEffect(() => {
    let active = true;
    setIsLoading(true);
    (async () => {
      try {
        let list: Audience[] = [];
        if (audiences.length === 0) {
          const response = await listAudiences(1, 50);
          list = response.data.items;
        } else {
          list = audiences;
        }

        if (active) {
          setAudiences(list);
          if (!list.length) {
            setCurrentAudience(null);
            return;
          }
        }

        let found;
        if (!id) {
          found = list[0];
          navigate(`/audiences/view/${found.id}`);
        } else {
          found = list.find((obj) => obj.id === parseInt(id));
        }
        if (active) {
          setCurrentAudience(found || null);
        }

        if (found) {
          setTotalContacts(found.numberOfContacts);
          const headers = await getAudienceHeaders(found.id);
          if (active) {
            setCurrentAudienceHeaders(headers.data);
          }
        }
      } catch (error: any) {
        setCurrentAudience(null);
        openToast("error", error.response?.data.message || error.message);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [id]);

  const handleRefreshData = async (newQuery: string) => {
    onCloseRefreshBq();
    if (currentAudience && newQuery) {
      setIsLoading(true);
      try {
        const response = await importContactsFromBigQuery(currentAudience.id, {
          query: newQuery,
        });
        setTotalContacts(response.data.totalContacts);
        setCurrentAudience({ ...currentAudience, query: newQuery });
        gridRef?.current?.api.refreshServerSideStore({ purge: true });
        openToast("success", "Successfully fetched new data");
      } catch (error: any) {
        openToast("error", error.response?.data.message || error.message);
      } finally {
        setIsLoading(false);
      }
    }
    return;
  };

  const onCloseRefreshBq = () => setOpenRefreshBq(false);
  const onSubmitRefreshBq = (query: string) => {
    handleRefreshData(query);
    onCloseRefreshBq();
  };

  return (
    <PageLayout>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} sx={{ width: "100%" }}>
          <Grid item xs={12} sx={{ display: { xxs: "none", xs: "block" } }}>
            <Typography variant="h4">Audience</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xxs: "none", xs: "block" } }}
          >
            <Typography variant="h5" sx={{ pb: 3 }}>
              Current audience
            </Typography>
            <FormControl fullWidth>
              <Select
                value={currentAudience?.name || ""}
                defaultValue={currentAudience?.name || ""}
                onChange={onChangeCurrentAudience}
              >
                {audiences.map((audience, index) => (
                  <MenuItem key={index} value={audience.name}>
                    <Typography variant="body1">{audience.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {currentAudience ? (
              <>
                <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
                  This audience has {totalContacts} contact
                  {totalContacts < 2 ? "" : "s"}.
                </Typography>
              </>
            ) : !isLoading && id?.length ? (
              <>
                <Typography variant="body1" color="error" sx={{ pt: 3, pb: 3 }}>
                  Audience not found or invalid.
                </Typography>
                <Typography variant="body1" sx={{ pb: 3 }}>
                  Select an audience from menu above to see its contacts.
                </Typography>
              </>
            ) : (
              <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
                Select an audience to see its contacts.
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xxs: "none", xs: "block" } }}
          ></Grid>
          {currentAudience && (
            <>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12} sx={{ display: { xxs: "none", xs: "block" } }}>
                <Typography variant="h5">View Contacts</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: { xxs: "none", xs: "block" } }}>
                {totalContacts > 0 ? (
                  <>
                    <CustomTableToolbar
                      emailSelected={selectedRows}
                      audience={currentAudience}
                      isLoading={isLoading}
                      onOpenRefreshBq={() => setOpenRefreshBq(true)}
                    />
                    <Box
                      className="ag-theme-alpine"
                      sx={{ width: "100%", height: 500, mb: 3 }}
                    >
                      <GridComponent
                        rowSelection={"multiple"}
                        onSelectionChanged={onSelection}
                        suppressRowClickSelection={true}
                        components={{
                          "contactLink": contactLink,
                        }}
                        columnDefs={columnDefs}
                        rowModelType={"serverSide"}
                        httpEndpoint={`/api/audiences/${currentAudience.id}/contacts?`}
                        pagination={true}
                        dataKey={"data"}
                        idKey="id"
                        gridRef={gridRef}
                        sideBar={{ toolPanels: ["columns"] }}
                      />
                    </Box>
                  </>
                ) : (
                  !isLoading && (
                    <Box>
                      <Typography variant="body1">
                        You have no contacts.
                      </Typography>
                      <Typography variant="body1">
                        <Link
                          component={RouterLink}
                          to={`/audiences/${currentAudience.id}/import`}
                          underline="none"
                        >
                          Import contacts
                        </Link>{" "}
                        to get started.
                      </Typography>
                    </Box>
                  )
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {currentAudience?.query && (
        <RefreshBQDialog
          open={openRefreshBq}
          currentQuery={currentAudience?.query}
          handleClose={onCloseRefreshBq}
          handleSubmit={onSubmitRefreshBq}
        />
      )}
    </PageLayout>
  );
};
