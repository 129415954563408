import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export const RefreshBQDialog = ({
  open,
  currentQuery,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  currentQuery: string;
  handleClose: () => void;
  handleSubmit: (query: string) => void;
}) => {
  const [query, setQuery] = useState<string>(currentQuery);
  const [error, setError] = useState<boolean>(false);
  const onClose = () => {
    setError(false);
    handleClose();
  };

  const onSubmit = (query: string) => {
    if (!query.trim().length) {
      setError(true);
      return;
    }
    handleSubmit(query);
    onClose();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Refresh BigQuery query</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ py: 2 }}>
          Which query should we fetch?
        </DialogContentText>
        <TextField
          autoFocus
          required
          label="Query"
          fullWidth
          multiline
          variant="outlined"
          value={query || ""}
          onChange={(event) => setQuery(event.target.value)}
          error={error}
        />
        <Typography sx={{ pt: 2, pb: 1 }}>
          All the old data will be cleared. Are you sure to fetch new data?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", py: 2, px: 3 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(query)}
        >
          Fetch
        </Button>
      </DialogActions>
    </Dialog>
  );
};
