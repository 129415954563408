import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { ReactNode } from "react";

export const DashboardItem = ({
  icon,
  title,
  description,
  children,
}: {
  icon: ReactNode;
  title: string;
  description: string;
  children?: ReactNode;
}) => {
  return (
    <Card>
      <CardHeader
        avatar={icon}
        title={<Typography variant="h5">{title}</Typography>}
        subheader={<Typography variant="body1">{description}</Typography>}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
