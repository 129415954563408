import { AlertColor } from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";

export const DEFAULT_AUTO_HIDE_DURATION = 4000;
export const DefaultAutoHideDuration: { [key in AlertColor]: number } = {
  "success": 3000,
  "warning": 4000,
  "info": 4000,
  "error": 4000,
};

// If we want to handle rendering many different toasts at the same time
// (i.e. while a toast is displaying (not closes yet), a different toast is triggered),
// we can assign each of them an id and listen to changes of toast's id
interface ToastProps {
  severity: AlertColor;
  message: ReactNode;
  autoHideDuration: number;
  open: boolean;
  onClose?: () => void;
}

export type ToastContextType = {
  toast: ToastProps | undefined;
  openToast: (
    severity: AlertColor,
    message: ReactNode,
    autoHideDuration?: number,
    onClose?: () => void,
  ) => void;
  closeToast: () => void;
};

export const ToastContext = createContext<ToastContextType>({
  toast: undefined,
  openToast: (
    _severity: AlertColor,
    _message: ReactNode,
    _autoHideDuration?: number,
    _onClose?: () => void,
  ) => console.warn("Toast is not ready to use"),
  closeToast: () => console.warn("Toast is not ready to use"),
});

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toastProps, setToastProps] = useState<ToastProps | undefined>(
    undefined,
  );

  const openToast = (
    severity: AlertColor,
    message: ReactNode,
    autoHideDuration?: number,
    onClose?: () => void,
  ) => {
    setToastProps({
      severity,
      message,
      autoHideDuration: autoHideDuration || DefaultAutoHideDuration[severity],
      open: true,
      onClose,
    });
  };
  (window as any).openToast = openToast;

  const closeToast = () =>
    setToastProps(toastProps ? { ...toastProps, open: false } : toastProps);

  return (
    <ToastContext.Provider value={{ toast: toastProps, openToast, closeToast }}>
      {children}
    </ToastContext.Provider>
  );
};
