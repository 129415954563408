import axios from "axios";

import { Contact } from "../types/contact";
import { Pagination } from "../types/http";
import { Job, JobStatus } from "../types/job";
import {
  IClickReport,
  ISubscriberMostOpen,
  ITopLocationByOpen,
  ITrackingTimeSeriesData,
  OverviewStat,
  SendingStat,
} from "../types/report";

export const getClickReport = (campaignId: number) => {
  return axios.post<IClickReport[]>("/api/reports/clicks", {
    campaignId,
  });
};

export const getCampaignOverviewStat = (campaignId: number) => {
  return axios.get<OverviewStat>(`/api/reports/${campaignId}/overview-stat`);
};

export const getCampaignSendingStat = (campaignId: number) => {
  return axios.get<SendingStat>(`/api/reports/${campaignId}/sending-stat`);
};

export const getCampaignSent = (
  campaignId: number,
  page = 1,
  limit = 10,
  status = JobStatus.SUCCESS,
) => {
  return axios.get<Pagination<Job>>(
    `/api/reports/${campaignId}/campaign-sent?page=${page}&limit=${limit}&status=${status}`,
  );
};

export const getTrackingTimeseriesData = (campaignId: number) => {
  return axios.get<ITrackingTimeSeriesData[]>(
    `/api/reports/${campaignId}/tracking-timeseries`,
  );
};

export const getContactsClickedByCampaign = (campaignId: number) => {
  return axios.get<Pagination<Contact>>(
    `/api/reports/${campaignId}/contacts-clicked`,
  );
};

export const getSubscribersWithMostOpens = (campaignId: number) => {
  return axios.get<ISubscriberMostOpen[]>(
    `/api/reports/${campaignId}/subscribers-most-opens`,
  );
};

export const getTopLocationsByOpens = (campaignId: number) => {
  return axios.get<ITopLocationByOpen[]>(
    `/api/reports/${campaignId}/top-locations-by-opens`,
  );
};

export const getContactOpenTracking = (
  campaignId: number,
  opened = true,
  page = 1,
  limit = 10,
) => {
  return axios.get<Pagination<Job>>(
    `/api/reports/${campaignId}/contact-open-tracking?page=${page}&limit=${limit}&opened=${opened}`,
  );
};
