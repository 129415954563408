import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UpdateIcon from "@mui/icons-material/Update";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  alpha,
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { exportAudience } from "../../http/audience";
import { Audience } from "../../types/audience";

export const CustomTableToolbar = ({
  emailSelected,
  audience,
  isLoading,
  onOpenRefreshBq,
}: {
  emailSelected: string[];
  audience: Audience;
  isLoading: boolean;
  onOpenRefreshBq: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const numSelected = emailSelected.length;

  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExportAudience = async () => {
    const response = await exportAudience(audience.id);

    const data = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = response.headers["content-disposition"]
      .split("; ")[1]
      .split("=")[1];
    link.click();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        </>
      ) : (
        <>
          <Box sx={{ flex: "1 1 100%" }} />
          {!!audience.query?.length && (
            <LoadingButton
              loading={isLoading}
              loadingPosition="end"
              endIcon={<UpdateIcon />}
              variant="contained"
              sx={{
                textTransform: "none",
                minWidth: "max-content",
                whiteSpace: "nowrap",
              }}
              onClick={onOpenRefreshBq}
            >
              Refresh Data
            </LoadingButton>
          )}
          <Button
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClickMenu}
            sx={{
              textTransform: "none",
              minWidth: "max-content",
              whiteSpace: "nowrap",
              ml: 1,
            }}
          >
            Options
          </Button>
          <Menu
            id="basic-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{ sx: { mt: 1 } }}
          >
            <Link
              component={RouterLink}
              to={`/audiences/${audience.id}/import`}
              underline="none"
              style={{ color: "inherit" }}
            >
              <MenuItem value="Import Contacts">
                <Typography>Import Contacts</Typography>
              </MenuItem>
            </Link>
            <Link
              component={RouterLink}
              to={`/audiences/${audience.id}/import/history`}
              underline="none"
              style={{ color: "inherit" }}
            >
              <MenuItem value="Import History">
                <Typography>Import History</Typography>
              </MenuItem>
            </Link>
            <MenuItem value="Export Contacts" onClick={handleExportAudience}>
              <Typography>Export Contacts</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </Toolbar>
  );
};
