import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC } from "react";

import { CURRENT_TIMEZONE_OFFSET } from "../../common/constants";
import theme from "../../common/theme";
import { Campaign, ITimezone } from "../../types/campaign";

interface IProps {
  open: boolean;
  title: string;
  timezones: ITimezone[];
  campaign: Campaign;
  handleClose: () => void;
  onSchedule: () => void;
  onDateChange: (value: Date | null) => void;
  onGMTChange: (value: number) => void;
}

const ScheduleDialog: FC<IProps> = ({
  open,
  title,
  campaign,
  timezones,
  handleClose,
  onSchedule,
  onDateChange,
  onGMTChange,
}) => {
  let value: Date;
  if (campaign.schedule) {
    const { year, month, day, hour, minute } = campaign.schedule;
    value = new Date(year, month, day, hour, minute);
  } else {
    value = new Date();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DateTimePicker
            renderInput={(props) => (
              <TextField
                sx={{ marginTop: theme.spacing(3) }}
                fullWidth
                {...props}
              />
            )}
            label="DateTimePicker"
            value={value}
            onChange={onDateChange}
            disablePast={true}
          />
          <FormControl fullWidth sx={{ marginTop: theme.spacing(3) }}>
            <InputLabel>GMT</InputLabel>
            <Select
              value={campaign.schedule?.gmt || CURRENT_TIMEZONE_OFFSET}
              label="GMT"
              onChange={(e) => onGMTChange(e.target.value as number)}
            >
              {timezones.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography component="div" mt={theme.spacing(1)}>
            Current timezone:{" "}
            <b>
              {Intl.DateTimeFormat().resolvedOptions().timeZone} (GMT{" "}
              {CURRENT_TIMEZONE_OFFSET >= 0 ? "+" : "-"}
              {CURRENT_TIMEZONE_OFFSET})
            </b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSchedule} variant="contained">
            Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ScheduleDialog;
