import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { OPEN_DRAWER_WIDTH } from "../../common/constants";
import theme from "../../common/theme";
import { BaseRoute, routes } from "../../constants/routes";
import { saveCampaign } from "../../http/campaign";

const useStyles = makeStyles({
  drawerPaper: {
    width: OPEN_DRAWER_WIDTH,
  },
  menuButton: {
    margin: 0,
    padding: theme.spacing(0, 1, 0, 0),
  },
  drawerBaseRoute: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  drawerSubRoute: {
    padding: theme.spacing(1, 1, 1, 5),
  },
});

const MobileDrawer = ({
  onCloseDrawer,
  onToggleDrawer,
  isOpen,
}: {
  onCloseDrawer: () => void;
  onToggleDrawer: () => void;
  isOpen?: boolean;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const indexOfMatchRoute = (array: BaseRoute[]): number => {
    const match = array.filter(
      (route) => location.pathname.indexOf(route.to) >= 0,
    );
    return match.length ? array.indexOf(match[0]) : -1;
  };

  const [selectedRoute, setSelectedRoute] = useState<number>(0);
  const [selectedSubRoute, setSelectedSubRoute] = useState<number>(0);

  const [openSubMenu, setOpenSubMenu] = useState<boolean[]>(
    Array(routes.length).fill(false),
  );

  useEffect(() => {
    const parent = indexOfMatchRoute(routes.map((route) => route.base));
    setSelectedRoute(parent);

    if (parent === -1) setSelectedSubRoute(-1);
    else setSelectedSubRoute(indexOfMatchRoute(routes[parent].sub));
  }, []);

  const onClickListRoute = (
    _event: MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    setOpenSubMenu(
      openSubMenu.map((menu, ind) => (ind === index ? !menu : menu)),
    );
  };

  const onClickListSubRoute = (
    _event: MouseEvent<HTMLDivElement>,
    index: number,
    subIndex: number,
  ) => {
    onCloseDrawer();
    const destination = routes[index].sub[subIndex].to;
    if (destination === "/campaigns/create") {
      createCampaign();
    } else navigate(destination);
  };

  const createCampaign = async () => {
    try {
      const response = await saveCampaign({
        name: "My Campaign",
      });
      navigate(`/campaigns/edit/${response.data.id}`);
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Drawer
      sx={{ display: { xxs: "none", xs: "block", sm: "none" } }}
      variant="temporary"
      open={isOpen}
      onClose={onToggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="primary"
          aria-label="open-main-drawer"
          onClick={onToggleDrawer}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Divider variant="middle" />
      <List>
        {routes.map((route, index) => (
          <Fragment key={index}>
            <ListItemButton
              className={classes.drawerBaseRoute}
              selected={selectedRoute === index}
              onClick={(event) => onClickListRoute(event, index)}
            >
              <ListItemIcon>
                {openSubMenu[index] ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={route.base.name} />
            </ListItemButton>
            <Collapse in={openSubMenu[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {routes[index].sub.map((subRoute, subIndex) => (
                  <ListItemButton
                    className={classes.drawerSubRoute}
                    key={subRoute.name}
                    selected={
                      selectedSubRoute === subIndex && selectedRoute === index
                    }
                    onClick={(event) =>
                      onClickListSubRoute(event, index, subIndex)
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight:
                              selectedSubRoute === subIndex &&
                              selectedRoute === index
                                ? 500
                                : 400,
                          }}
                        >
                          {subRoute.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default MobileDrawer;
