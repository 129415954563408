import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { MouseEvent, useState } from "react";

import theme from "../../common/theme";
import { useAuth } from "../../contexts/AuthContext";
import { useScroll } from "../../hooks/useScroll";
import { keycloak } from "../../libs/keycloak";
import { TextAvatar } from "../TextAvatar/TextAvatar";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
  },
  rootTrigger: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: theme.palette.divider,
  } as const,
  toolbar: {
    flexGrow: 1,
    margin: theme.spacing(0, 2),
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      paddingLeft: 0,
    },
  },
  accountButton: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
});

function Header({ onToggleDrawer }: { onToggleDrawer?: () => void }) {
  const { profile } = useAuth();
  const classes = useStyles();
  const trigger = useScroll();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const onLogout = async () => {
    keycloak.logout();
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        className={trigger ? classes.root : classes.rootTrigger}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: { xs: "block", sm: "none" },
        }}
        position="fixed"
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open-main-drawer"
            onClick={onToggleDrawer}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            sx={{ width: 40, height: 40, mx: 2 }}
            alt="STEAMify"
            src="https://steamese-profile.sgp1.digitaloceanspaces.com/assets/images/logo/logo-only@512x512.png"
          />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: "flex" } }}>
            <IconButton
              edge="end"
              color="primary"
              aria-label="account of current user"
              aria-haspopup="true"
              className={classes.accountButton}
              onClick={handleClick}
              size="large"
            >
              <TextAvatar
                displayedName={profile?.firstName || ""}
                sx={{ width: 32, height: 32 }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem disabled>
                {(profile?.firstName || "") + " " + (profile?.lastName || "")}
              </MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar
        id="back-to-top-anchor"
        sx={{ minHeight: { sm: "24px !important" } }}
      />
    </>
  );
}

export default Header;
