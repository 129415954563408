import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import moment from "moment";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import theme from "../../common/theme";
import { audienceHeaders } from "../../constants/audience";
import { useToast } from "../../contexts/ToastContext";
import { deleteAudience } from "../../http/audience";
import { Audience } from "../../types/audience";
import GridComponent from "../GridComponent";
import PageLayout from "../Layout/PageLayout";

import { CustomTableToolbar } from "./CustomTableToolbar";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(4, 0),
  },
});

export const AudiencesList = () => {
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState<Audience[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact | null>(null);
  const { openToast } = useToast();

  const onSelection = (event: SelectionChangedEvent) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const onDeleteAudience = async () => {
    if (!selectedRows.length) return;
    try {
      const response = await deleteAudience({
        ids: selectedRows.map((row) => row.id),
      });
      if (!response) return;

      const data = response.data;
      if (data.fail.length) {
        if (!data.success.length) {
          openToast("error", "Error occured when deleting audience");
        } else {
          openToast(
            "warning",
            <>
              <div>{`Successfully delete audience ${data.success
                .map((obj) => obj.name)
                .join(", ")}`}</div>
              <div>{`Error occured when deleting audience ${data.fail
                .map((obj) => obj.name)
                .join(", ")}`}</div>
            </>,
          );
        }
      } else {
        openToast(
          "success",
          `Successfully delete audience ${data.success
            .map((obj) => obj.name)
            .join(", ")}`,
        );
      }

      gridRef?.current?.api
        .getSelectedNodes()
        .forEach((node) => node.setSelected(false));
      gridRef?.current?.api.refreshServerSideStore({ purge: true });
      setSelectedRows([]);
    } catch (error: any) {
      openToast("error", error.response?.data.message || error.message);
      console.error(error);
    }
  };

  const onCloseDialog = () => setOpenDialog(false);
  const onSubmitDeletion = () => {
    onDeleteAudience();
    onCloseDialog();
  };

  const columnDefs = audienceHeaders.map((item) => {
    return {
      field: item.field,
      headerName: item.header,
      checkboxSelection: item.field === "name",
      pinned: item.field === "name" ? "left" : undefined,
      cellRenderer:
        item.field === "name"
          ? "audienceLink"
          : item.field === "createdAt"
          ? "formatTime"
          : undefined,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const audienceLink = (params: any) => {
    const audience = params.data as Audience;
    return (
      <Link
        component={RouterLink}
        to={`/audiences/view/${audience.id}`}
        underline="none"
      >
        <Typography sx={{ fontWeight: "medium" }}>{audience.name}</Typography>
      </Link>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatTime = (params: any) => {
    const audience = params.data as Audience;
    return moment(audience.createdAt).format("YYYY-MM-DD HH:mm:ss A");
  };

  return (
    <PageLayout>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h4" sx={{ pb: 3 }}>
          List of Audiences
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 3 }} />
        <CustomTableToolbar
          numSelected={selectedRows.length}
          openDeletionDialog={() => setOpenDialog(true)}
        />
        <Box
          className="ag-theme-alpine"
          sx={{ width: "100%", height: 500, mt: theme.spacing(3) }}
        >
          <GridComponent
            rowSelection={"multiple"}
            onSelectionChanged={onSelection}
            suppressRowClickSelection={true}
            components={{
              "audienceLink": audienceLink,
              "formatTime": formatTime,
            }}
            columnDefs={columnDefs}
            rowModelType={"serverSide"}
            httpEndpoint={"/api/audiences?"}
            pagination={true}
            gridRef={gridRef}
            sideBar={{ toolPanels: ["columns"] }}
          />
        </Box>
      </Container>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deletion confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="div">
            <Typography>
              Are you sure to delete the following audience
              {selectedRows.length > 1 ? "s" : ""}?
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", pt: 1 }}
            >
              {selectedRows.map((row) => row.name).join(", ")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button onClick={onSubmitDeletion} autoFocus>
            Go ahead
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};
