import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ChangeEvent, MouseEvent, ReactNode } from "react";

import { PaginationMeta } from "../../types/http";

import { CustomPaginationActions } from "./CustomPaginationActions";

interface CustomTableProps {
  tableHeads?: ReactNode;
  tableBody: ReactNode;
  pageMeta: PaginationMeta;
  amountColumns: number;
  handleChangePage: (
    _event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  stickyHeader?: boolean;
  children?: ReactNode;
}

export const CustomTable = ({
  tableHeads,
  tableBody,
  pageMeta,
  amountColumns,
  handleChangePage,
  handleChangeRowsPerPage,
  stickyHeader = true,
  children,
}: CustomTableProps) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          stickyHeader={stickyHeader}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>{tableHeads}</TableHead>
          <TableBody>{tableBody}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={amountColumns}
                count={pageMeta.totalItems}
                rowsPerPage={pageMeta.itemsPerPage}
                page={pageMeta.currentPage - 1}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomPaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {children}
    </>
  );
};
