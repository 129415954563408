import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import theme from "../../common/theme";
import { createAudience } from "../../http/audience";
import PageLayout from "../Layout/PageLayout";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(4, 0),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  description: {
    fontStyle: "italic",
  },
});

export const AudienceCreator = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [helperText, setHelperText] = useState<string>("");

  const onChangeName = (
    event:
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<string>,
  ) => {
    setName(event.target.value);
  };

  const onCreateAudience = () => {
    if (!name.trim().length) {
      setHelperText("Fill your audience name");
      return;
    }
    setHelperText("");

    (async () => {
      try {
        const response = await createAudience({ name });
        navigate(`/audiences/view/${response.data.id}`);
      } catch (error: any) {
        setHelperText(error.response?.data.message || error.message);
      }
    })();
  };

  return (
    <PageLayout>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h4" sx={{ pb: 3 }}>
          Create New Audience
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 3 }} />
        <Box sx={{ mt: 5, minHeight: "300px" }}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ mb: 5 }}
          >
            {name ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            <Stack spacing={2} direction="column">
              <Typography>Audience name</Typography>
              <Typography className={classes.description}>
                Your subscribers will see this, so make it something
                appropriate.
              </Typography>
              <FormControl fullWidth>
                <InputLabel htmlFor="my-input">Audience name</InputLabel>
                <Input
                  id="my-input"
                  required
                  aria-describedby="my-helper-text"
                  value={name || ""}
                  onChange={onChangeName}
                  error={!!helperText.length}
                />
                <FormHelperText id="my-helper-text" component="div">
                  <Typography color="error">{helperText}</Typography>
                </FormHelperText>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onCreateAudience}>
            Save
          </Button>
        </Stack>
      </Container>
    </PageLayout>
  );
};
