import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { ReactNode, useState } from "react";

import { CLOSE_DRAWER_WIDTH, OPEN_DRAWER_WIDTH } from "../../common/constants";
import theme from "../../common/theme";
import { keycloak } from "../../libs/keycloak";
import DesktopDrawer from "../Drawer/DesktopDrawer";
import MobileDrawer from "../Drawer/MobileDrawer";

import Header from "./Header";

const useStyles = makeStyles({
  main: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainFull: {
    width: `calc(100% - ${CLOSE_DRAWER_WIDTH}px)`,
    marginLeft: CLOSE_DRAWER_WIDTH,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: theme.spacing(1),
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainShift: {
    width: `calc(100% - ${OPEN_DRAWER_WIDTH}px)`,
    marginLeft: OPEN_DRAWER_WIDTH,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: theme.spacing(1),
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const PageLayout = ({ children }: { children?: ReactNode }) => {
  const classes = useStyles();
  const [canOpenDrawer, setCanOpenDrawer] = useState<boolean>(false);

  const onOpenDrawer = () => {
    setCanOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setCanOpenDrawer(false);
  };
  const onToggleDrawer = () => {
    setCanOpenDrawer(!canOpenDrawer);
  };

  const onLogout = async () => {
    keycloak.logout();
  };

  return (
    <>
      {/* Header */}
      <Header onToggleDrawer={onToggleDrawer} />
      {/* Drawer */}
      <DesktopDrawer
        onCloseDrawer={onCloseDrawer}
        onOpenDrawer={onOpenDrawer}
        isOpen={canOpenDrawer}
        onToggleDrawer={onToggleDrawer}
        onLogout={onLogout}
      />
      <MobileDrawer
        onCloseDrawer={onCloseDrawer}
        isOpen={canOpenDrawer}
        onToggleDrawer={onToggleDrawer}
      />
      {/* Main */}
      <main
        className={clsx(classes.main, {
          [classes.mainShift]: canOpenDrawer,
          [classes.mainFull]: !canOpenDrawer,
        })}
      >
        {children}
      </main>
    </>
  );
};

export default PageLayout;
