import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import LoadingButton from "@mui/lab/LoadingButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import debounce from "debounce-promise";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { CURRENT_TIMEZONE_OFFSET } from "../common/constants";
import theme from "../common/theme";
import AlertDialog from "../components/CampaignCreator/AlertDialog";
import CampaignPreviewDialog from "../components/CampaignCreator/CampaignPreviewDialog";
import ScheduleDialog from "../components/CampaignCreator/ScheduleDialog";
import PageLayout from "../components/Layout/PageLayout";
import { listAudiences } from "../http/audience";
import {
  getAuthorizedSentEmails,
  getCampaignById,
  getTimezones,
  scheduleCampaign,
  sendCampaignNow,
  sendTestEmail,
  updateCampaign,
} from "../http/campaign";
import { listTemplates } from "../http/template";
import { Audience } from "../types/audience";
import { Campaign, CAMPAIGN_STATUS, ITimezone } from "../types/campaign";
import { Template } from "../types/template";

const useStyles = makeStyles(() => ({
  description: {
    fontStyle: "italic",
  },
  templateImg: {
    marginBottom: theme.spacing(2),
    height: 250,
    border: "1px solid #dedddc",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 200,
  },
  template: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(1),
    transition: "background-color .2s",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  templateName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(100% - 100px)",
  },
}));

export default function CampaignCreator() {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newCampaignName, setNewCampaignName] = useState<string>("");
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
  const [authorizedSentEmails, setAuthorizedSentEmails] = useState<string[]>(
    [],
  );
  const [timezones, setTimezones] = useState<ITimezone[]>([]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Template[]>([]);
  const loading = open && options.length === 0;

  const [open1, setOpen1] = useState(false);
  const [options1, setOptions1] = useState<Audience[]>([]);
  const loading1 = open1 && options1.length === 0;
  const [isTestEmailModalVisible, setIsTestEmailModalVisible] =
    useState<boolean>(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState<boolean>(false);
  const [isConfirmedSendVisible, setIsConfirmedSendVisible] =
    useState<boolean>(false);
  const [isTestEmailSent, setIsTestEmailSent] = useState<boolean>(false);
  const [testEmails, setTestEmails] = useState<string>("");
  const [isPreviewModalOpened, setIsPreviewModalOpened] =
    useState<boolean>(false);

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  const [scheduleDialog, setScheduleDialog] = useState({
    open: false,
    title: "",
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await listTemplates("", 1, 10);

      if (active) {
        setOptions(response.data.items);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // AUDIENCE
  useEffect(() => {
    let active = true;

    if (!loading1) {
      return undefined;
    }

    (async () => {
      const response = await listAudiences(1, 50);

      if (active) {
        setOptions1(response.data.items);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading1]);

  useEffect(() => {
    if (!open1) {
      setOptions1([]);
    }
  }, [open1]);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      const response = await getCampaignById(parseInt(id));

      setCampaign(response.data);
      setNewCampaignName(response.data.name);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getAuthorizedSentEmails();
      setAuthorizedSentEmails(response.data);
    })();
  }, []);

  useEffect(() => {
    if (scheduleDialog.open && !timezones.length) {
      (async () => {
        const response = await getTimezones();
        setTimezones(response.data);
      })();
    }
  }, [scheduleDialog.open]);

  const handleChangeFromName = (event: ChangeEvent<HTMLInputElement>) => {
    campaign &&
      setCampaign({
        ...campaign,
        fromName: event.target.value,
      });
  };
  const handleChangeFromEmail = (event: SelectChangeEvent<string>) => {
    campaign &&
      setCampaign({
        ...campaign,
        fromEmail: event.target.value,
      });
  };

  const handleChangeSubjectText = (event: ChangeEvent<HTMLInputElement>) => {
    campaign &&
      setCampaign({
        ...campaign,
        subject: event.target.value,
      });
  };

  const handleChangeSubjectPreviewText = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    campaign &&
      setCampaign({
        ...campaign,
        previewText: event.target.value,
      });
  };

  const onAudienceChange = (
    _: SyntheticEvent<Element, Event>,
    newVal: Audience | null,
  ) => {
    campaign &&
      newVal &&
      setCampaign({
        ...campaign,
        audienceId: newVal.id,
        audienceName: newVal.name,
        numberOfContacts: newVal.numberOfContacts,
      });
  };

  const onTemplateChange = (
    _: SyntheticEvent<Element, Event>,
    newVal: Template | null,
  ) => {
    campaign &&
      newVal &&
      setCampaign({
        ...campaign,
        template: {
          ...newVal,
        },
      });
  };

  const onScheduleDateChange = (value: Date | null) => {
    campaign &&
      setCampaign({
        ...campaign,
        schedule: value
          ? {
              year: value.getFullYear(),
              month: value.getMonth(),
              day: value.getDate(),
              hour: value.getHours(),
              minute: value.getMinutes(),
              gmt: CURRENT_TIMEZONE_OFFSET,
            }
          : undefined,
      });
  };
  const onScheduleGMTChange = (value: number) => {
    campaign &&
      campaign.schedule &&
      setCampaign({
        ...campaign,
        schedule: {
          ...campaign.schedule,
          gmt: value,
        },
      });
  };

  const updateCampaignName = async () => {
    if (!campaign) {
      return;
    }
    try {
      await _updateCampaign({ name: newCampaignName });

      setCampaign({
        ...campaign,
        name: newCampaignName,
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsEdit(false);
    }
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case CAMPAIGN_STATUS.SCHEDULED: {
        return <Chip label={status} color="secondary" />;
      }
      case CAMPAIGN_STATUS.PAUSED: {
        return <Chip label={status} color="warning" />;
      }
      case CAMPAIGN_STATUS.SENT: {
        return <Chip label={status} color="success" />;
      }
      default: {
        return <Chip label={status} />;
      }
    }
  };

  const send = async () => {
    if (!campaign) {
      return;
    }
    try {
      await _updateCampaign();

      await sendCampaignNow(campaign.id);
      navigate("/campaigns/list");
    } catch (error: any) {
      console.error(error);
    }
  };

  const schedule = async () => {
    if (!campaign) {
      return;
    }
    try {
      // await _updateCampaign();
      await scheduleCampaign(campaign.id, {
        name: campaign.name,
        audienceId: campaign.audienceId,
        fromName: campaign.fromName,
        fromEmail: campaign.fromEmail,
        subject: campaign.subject,
        previewText: campaign.previewText,
        templateId: campaign.template?.id,
        schedule: campaign.schedule,
      });
      navigate("/campaigns/list");
    } catch (error: any) {
      console.error(error);
    }
  };

  const _updateCampaign = async (payload?: Partial<Campaign>) => {
    if (!campaign) {
      return;
    }
    try {
      await updateCampaign(
        campaign.id,
        payload || {
          name: campaign.name,
          audienceId: campaign.audienceId,
          audienceName: campaign.audienceName,
          fromName: campaign.fromName,
          fromEmail: campaign.fromEmail,
          subject: campaign.subject,
          previewText: campaign.previewText,
          templateId: campaign.template?.id,
          schedule: campaign.schedule,
        },
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const _finishLater = async () => {
    if (!campaign) {
      return;
    }
    try {
      await _updateCampaign();

      navigate("/campaigns/list");
    } catch (error: any) {
      console.error(error);
    }
  };

  const _sendTestEmail = async () => {
    if (!campaign) {
      return;
    }

    setIsSendingTestEmail(true);
    try {
      await sendTestEmail({
        emails: testEmails,
        fromEmail: campaign.fromEmail || "",
        fromName: campaign.fromName || "",
        previewText: campaign.previewText || "",
        subject: campaign.subject || "",
        templateId: campaign.template?.id || 0,
      });
      setIsTestEmailSent(true);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSendingTestEmail(false);
    }
  };

  const _searchAudience = async (value: string) => {
    const response = await listAudiences(1, 10, { name: value });
    setOptions1(response.data.items);
  };

  const _searchAudienceDebounce = debounce(_searchAudience, 1000);

  const handlePreviewModalClose = () => {
    setIsPreviewModalOpened(false);
  };

  const validateCampaignInputs = (
    type: "testEmailDialog" | "previewCampaignDialog" | "send" | "schedule",
  ) => {
    const result = {
      success: false,
      message: "",
    };
    if (!campaign?.audienceId) {
      result.message = "Please select an audience";
    } else if (!campaign?.template) {
      result.message = "Please select a template";
    } else if (!campaign.subject?.trim().length) {
      result.message = "Please input email subject";
    } else if (!campaign.fromName?.trim().length) {
      result.message = "Please input name for who's sending this campaign";
    } else if (!campaign.fromEmail?.trim().length) {
      result.message = "Please input email for who's sending this campaign";
    } else if (type === "schedule" && !campaign.schedule) {
      // TODO: validate GMT
      result.message =
        "Please select a schedule and GMT time for your campaign";
    } else {
      result.success = true;
    }

    if (!result.success) {
      setAlertDialog({
        open: true,
        title: "Error",
        message: result.message,
      });
    } else {
      switch (type) {
        case "testEmailDialog": {
          setIsTestEmailModalVisible(true);
          break;
        }
        case "previewCampaignDialog": {
          setIsPreviewModalOpened(true);
          break;
        }
        case "send": {
          // send();
          setIsConfirmedSendVisible(true);
          break;
        }
        case "schedule": {
          schedule();
          break;
        }
        default: {
          break;
        }
      }
    }

    return result;
  };

  if (campaign === undefined) {
    return <PageLayout></PageLayout>;
  }

  return (
    <PageLayout>
      {campaign ? (
        <>
          <Stack direction="row" spacing={2} marginBottom={2}>
            <Stack direction="row" flex={1} spacing={2} alignItems="center">
              <MarkEmailUnreadIcon style={{ color: "#bbb" }} />
              <Stack direction="column">
                <b>Keep it up!</b>
                <div>Draft Email</div>
              </Stack>
              {renderStatus(campaign?.status || "")}
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                color="primary"
                sx={{ height: 45 }}
                onClick={_finishLater}
              >
                Finish later
              </Button>
              <Button
                variant="outlined"
                sx={{ height: 45 }}
                onClick={() =>
                  setScheduleDialog({
                    open: true,
                    title: "Schedule Your Campaign",
                  })
                }
              >
                Schedule
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ height: 45 }}
                onClick={() => validateCampaignInputs("send")}
              >
                Send
              </Button>
            </Stack>
          </Stack>
          <Divider></Divider>
          <Stack direction="row" spacing={2} marginY={5}>
            <Box sx={{ flex: 1 }}>
              {isEdit ? (
                <Stack direction="column" spacing={2}>
                  <TextField
                    label="Name..."
                    variant="outlined"
                    style={{ width: 500 }}
                    value={newCampaignName}
                    onChange={(e) => setNewCampaignName(e.target.value)}
                  />
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={updateCampaignName}>
                      Save
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setNewCampaignName("");
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Box>
                  <Typography variant="h4" style={{ flex: 1 }}>
                    {campaign.name}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => setIsEdit(true)}
                    sx={{ mr: theme.spacing(2) }}
                  >
                    Edit name
                  </Button>
                </Box>
              )}
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="outlined"
                color="success"
                sx={{ height: 36 }}
                size="small"
                onClick={() => validateCampaignInputs("testEmailDialog")}
              >
                Send test email
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{ height: 36 }}
                onClick={() => validateCampaignInputs("previewCampaignDialog")}
              >
                Preview Campaign
              </Button>
            </Stack>
          </Stack>
          <Box sx={{ marginTop: theme.spacing(5) }}>
            <Accordion disabled={isEdit}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Stack spacing={2} direction="row" flex={1}>
                  {campaign.audienceId ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                  <Stack spacing={2} direction="column">
                    <Typography>To</Typography>
                    <Typography className={classes.description}>
                      Who are you sending this campaign to?
                    </Typography>
                  </Stack>
                </Stack>
                {campaign.audienceId && (
                  <Typography
                    sx={{ color: "text.secondary", mr: theme.spacing(3) }}
                  >
                    To: {campaign.audienceName} ({campaign.numberOfContacts}{" "}
                    contacts)
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Container maxWidth="sm">
                  <Autocomplete
                    sx={{ width: 300 }}
                    open={open1}
                    filterOptions={(x) => x}
                    onInputChange={(_, newInputValue) =>
                      _searchAudienceDebounce(newInputValue)
                    }
                    onOpen={() => {
                      setOpen1(true);
                    }}
                    onClose={() => {
                      setOpen1(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      option.id
                        ? `${option.name} (${option.numberOfContacts} contacts)`
                        : ""
                    }
                    options={options1}
                    loading={loading1}
                    value={
                      {
                        id: campaign.audienceId,
                        name: campaign.audienceName,
                        numberOfContacts: campaign.numberOfContacts,
                      } as Audience
                    }
                    onChange={onAudienceChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select audience"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading1 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Container>
              </AccordionDetails>
            </Accordion>
            <Accordion disabled={isEdit}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Stack spacing={2} direction="row" flex={1}>
                  {campaign.fromName && campaign.fromEmail ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                  <Stack spacing={2} direction="column">
                    <Typography>From</Typography>
                    <Typography className={classes.description}>
                      Who is sending this campaign?
                    </Typography>
                  </Stack>
                </Stack>
                <Box>
                  {campaign.fromName?.trim().length && (
                    <Typography
                      sx={{ color: "text.secondary", mr: theme.spacing(3) }}
                    >
                      Name: {campaign.fromName}
                    </Typography>
                  )}
                  {campaign.fromEmail?.trim().length && (
                    <Typography
                      sx={{ color: "text.secondary", mr: theme.spacing(3) }}
                    >
                      Email: {campaign.fromEmail}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    helperText="Use something subscribers will instantly recognize, like your company name."
                    value={campaign.fromName}
                    onChange={handleChangeFromName}
                  />
                  <FormControl fullWidth>
                    <InputLabel>Email</InputLabel>
                    <Select
                      value={campaign.fromEmail || ""}
                      label="Email"
                      onChange={handleChangeFromEmail}
                    >
                      {authorizedSentEmails.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion disabled={isEdit}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Stack spacing={2} direction="row" flex={1}>
                  {campaign.subject && campaign.previewText ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                  <Stack spacing={2} direction="column">
                    <Typography>Subject</Typography>
                    <Typography className={classes.description}>
                      What&apos;s the subject line for this campaign?
                    </Typography>
                  </Stack>
                </Stack>
                <Box>
                  {campaign.subject?.trim().length && (
                    <Typography
                      sx={{ color: "text.secondary", mr: theme.spacing(3) }}
                    >
                      Subject: {campaign.subject}
                    </Typography>
                  )}
                  {campaign.previewText?.trim().length && (
                    <Typography
                      sx={{ color: "text.secondary", mr: theme.spacing(3) }}
                    >
                      Preview Text: {campaign.previewText}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="column">
                  <TextField
                    fullWidth
                    label="Subject"
                    variant="outlined"
                    helperText="See how your recent subject lines performed. View our subject line guide
                Add the subject for your email before saving."
                    value={campaign.subject || ""}
                    onChange={handleChangeSubjectText}
                  />
                  <TextField
                    fullWidth
                    label="Preview Text"
                    variant="outlined"
                    helperText="Preview text appears in the inbox after the subject line."
                    value={campaign.previewText || ""}
                    onChange={handleChangeSubjectPreviewText}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion disabled={isEdit}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Stack spacing={2} direction="row" flex={1}>
                  {campaign.template ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                  <Stack spacing={2} direction="column">
                    <Typography>Content</Typography>
                    <Typography className={classes.description}>
                      Choose the template for your email.
                    </Typography>
                  </Stack>
                </Stack>
                <Box>
                  {campaign.template && (
                    <Typography
                      sx={{ color: "text.secondary", mr: theme.spacing(3) }}
                    >
                      Template: {campaign.template.name}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Container
                  maxWidth="sm"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className={classes.templateImg}
                    style={{
                      backgroundImage: `url(${
                        campaign.template?.preview ||
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAJFBMVEX4+vvb4OTx9PXl6ezf4+f19/nt8PLz9vfq7fDi5unc4eTs7/GWhAgrAAACGUlEQVR4nO3b23KqMBiAUQEtsn3/961OtydIJEUmaXCtS7n5v5FTEHc7AAAAAAAAAAAAAAAAAAAAAAAAzvr2PX3pgNf6oXnf8Icjjyv0XZxKh8R8rRTYNF+lU8IOqwU2zaF0TNBa++jFsXRM0H7Fwn3pmKAVA5umdEzQhxXuT/3h0B7/bbbwdrpfevopGRIVnq/dZOHT1WzZfUCpiJdu040uZovuVcskzIhNt2g/LVIw5zrcENuwmcLJHdeS3bREwKzrcJO1z+YKJ99h/IZ138bOtCUCZl2HSz8OL/fXkTVXgfnnxaaLnkt/FhDhxPzjJ7hNNzoQY4fhdYUUTMw+fYr7eEn3NPclYCgx+/QpwvPF9tHHNW4gMffwSR4HbK8fxtYWz4v4aWL26VM8F5z68/cXXTuNn1JMEosUzInVJAROEwvMP++dwEli9ulTvBU4Tsw8e5r3AkeJWSdPFaoZ+lNq4HNixrnTBQK73eSC+OpZ7+H+aC7X0L8SDhwlvn6Y3VVW2P3f0KYG1lbY3ba0iYGVFXYPm9q0wLoKu6dtbVJgVYXdaGOb9ItZRYXjwPMyMeUnwXoKp4FpqilcGlhN4eRhW7JaChd/hQpLU5hAYWEfVDgsfrl0qKRwDaVjghTWX7j9dxO3/37pmrtp6ZSIzb/nvdtNnm8v9Gff1f+A/1tcbPw/MwAAAAAAAAAAAAAAAAAAAAAAALl8A7+jEA62Pbx2AAAAAElFTkSuQmCC"
                      })`,
                    }}
                  ></div>
                  <Autocomplete
                    sx={{ width: 300 }}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={onTemplateChange}
                    value={campaign.template}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select template"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Container>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      ) : (
        <>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>No Campaign Found!</strong>
          </Alert>
          <Button
            variant="contained"
            fullWidth
            sx={{ marginTop: theme.spacing(2) }}
            component={RouterLink}
            to="/campaigns/list"
          >
            Back to homepage
          </Button>
        </>
      )}
      <Dialog
        open={isTestEmailModalVisible}
        onClose={() => setIsTestEmailModalVisible(false)}
      >
        <DialogTitle>Send a Test Email</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Email Addresses"
            type="text"
            fullWidth
            helperText="Use commas to separate multiple emails."
            variant="standard"
            value={testEmails}
            onChange={(e) => setTestEmails(e.target.value)}
          />
          {isTestEmailSent && (
            <Alert
              severity="success"
              onClose={() => setIsTestEmailSent(false)}
              sx={{ mt: theme.spacing(2) }}
            >
              Test email sent. Please check your inbox
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTestEmailModalVisible(false)}>
            Close
          </Button>
          <LoadingButton
            onClick={_sendTestEmail}
            loading={isSendingTestEmail}
            variant="contained"
          >
            Send Test
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isConfirmedSendVisible}
        onClose={() => setIsConfirmedSendVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to send this campaign?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure all your information for your campaign are correct.
            This campaign will be sent{" "}
            <b>immediately to {campaign.numberOfContacts} contacts</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmedSendVisible(false)}>
            Close
          </Button>
          <Button color="error" onClick={() => send()} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CampaignPreviewDialog
        open={isPreviewModalOpened}
        campaign={campaign}
        handleClose={handlePreviewModalClose}
      />
      <AlertDialog
        {...alertDialog}
        handleClose={() =>
          setAlertDialog({ open: false, title: "", message: "" })
        }
      />
      <ScheduleDialog
        {...scheduleDialog}
        timezones={timezones}
        campaign={campaign}
        handleClose={() => setScheduleDialog({ open: false, title: "" })}
        onDateChange={onScheduleDateChange}
        onGMTChange={onScheduleGMTChange}
        onSchedule={() => {
          setScheduleDialog({ open: false, title: "" });
          validateCampaignInputs("schedule");
        }}
      />
    </PageLayout>
  );
}
