import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import theme from "../../common/theme";
import { getAudienceById } from "../../http/audience";
import { Audience } from "../../types/audience";
import PageLayout from "../Layout/PageLayout";

import { ChooseImportMethod } from "./ChooseImportMethod";
import { CompleteImport } from "./CompleteImport";
import { ImportContacts } from "./ImportContacts";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(4, 0),
  },
  step: {
    padding: theme.spacing(4, 10),
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
});

export const AudienceImport = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [importMethod, setImportMethod] = useState<string>("");
  const [audience, setAudience] = useState<Audience | null>(null);

  useEffect(() => {
    let active = true;
    (async () => {
      try {
        if (!id) {
          return;
        }

        const response = await getAudienceById(parseInt(id));
        if (active) {
          setAudience(response.data || null);
          setImportMethod(response.data.source || "");
        }
      } catch (error: any) {
        console.error(error.response?.data.message || error.message);
      }
    })();
    return () => {
      active = false;
    };
  }, []);

  const onChangeImportMethod = (event: ChangeEvent<HTMLInputElement>) => {
    setImportMethod(event.target.value);
  };

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleResetStep = (step: number) => {
    setActiveStep(step);
  };

  const importSteps: { label: string; component: ReactNode }[] = [
    {
      label: "Choose import method",
      component: (
        <ChooseImportMethod
          audience={audience}
          method={importMethod}
          onChangeImportMethod={onChangeImportMethod}
          handleNextStep={handleNextStep}
        />
      ),
    },
    {
      label: "Import",
      component: (
        <ImportContacts
          audience={audience}
          method={importMethod}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          handleResetStep={handleResetStep}
        />
      ),
    },
    {
      label: "Complete",
      component: <CompleteImport audience={audience} />,
    },
  ];

  return (
    <PageLayout>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h4" sx={{ pb: 3 }}>
          Import Contacts
        </Typography>
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {importSteps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box className={classes.step}>{importSteps[activeStep].component}</Box>
      </Container>
    </PageLayout>
  );
};
